import './Menu.svelte.css';
/* src/lib/components/Menu.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	attr,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import Button from "../atoms/Button.svelte.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	return child_ctx;
}

// (15:1) {#each buttons as prop}
function create_each_block(ctx) {
	let button;
	let current;

	function click_handler() {
		return /*click_handler*/ ctx[3](/*prop*/ ctx[4]);
	}

	button = new Button({
			props: {
				prop: /*prop*/ ctx[4],
				current: /*current*/ ctx[0]
			}
		});

	button.$on("click", click_handler);

	return {
		c() {
			create_component(button.$$.fragment);
		},
		m(target, anchor) {
			mount_component(button, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const button_changes = {};
			if (dirty & /*current*/ 1) button_changes.current = /*current*/ ctx[0];
			button.$set(button_changes);
		},
		i(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(button, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let current;
	let each_value = /*buttons*/ ctx[2];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "data-testid", "menu");
			attr(div, "class", "container fixed svelte-m3gj2f");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*buttons, current, selectedBtn*/ 7) {
				each_value = /*buttons*/ ctx[2];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const buttons = [
		{
			icon: "home",
			style: "secondary",
			selected: false
		},
		{
			icon: "lightning",
			style: "secondary",
			selected: false
		},
		{
			icon: "chart",
			style: "secondary",
			selected: false
		},
		{
			icon: "tune",
			style: "secondary",
			selected: false
		}
	];

	let { current = "Home" } = $$props;

	let { selectedBtn = icon => {
		console.log(icon);
	} } = $$props;

	const click_handler = prop => selectedBtn(prop.icon);

	$$self.$$set = $$props => {
		if ("current" in $$props) $$invalidate(0, current = $$props.current);
		if ("selectedBtn" in $$props) $$invalidate(1, selectedBtn = $$props.selectedBtn);
	};

	return [current, selectedBtn, buttons, click_handler];
}

class Menu extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { current: 0, selectedBtn: 1 });
	}
}

export default Menu;