/* src/lib/pages/User.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	outro_and_destroy_block,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out,
	update_keyed_each
} from "../../../_snowpack/pkg/svelte/internal.js";

import { actionSignOut, signIn } from "../../firebase/firebase-auth.js";
import Button from "../atoms/Button.svelte.js";
import Card from "../atoms/Card.svelte.js";
import Tooltip from "../atoms/Tooltip.svelte.js";
import { user } from "../../stores/user.js";
import LangSelect from "../atoms/LangSelect.svelte.js";
import CurrencySelect from "../atoms/CurrencySelect.svelte.js";
import { t } from "../../i18n/i18n.js";
import Feedback from "../atoms/Feedback.svelte.js";
import Switch from "../atoms/Switch.svelte.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i].label;
	child_ctx[5] = list[i].icon;
	child_ctx[6] = list[i].style;
	child_ctx[7] = list[i].onClick;
	child_ctx[8] = list[i].name;
	return child_ctx;
}

// (44:2) {:else}
function create_else_block(ctx) {
	let h2;
	let t0_value = /*$t*/ ctx[0]("PROFILE.OFFLINE.TITLE") + "";
	let t0;
	let t1;
	let div;
	let tooltip;
	let current;

	tooltip = new Tooltip({
			props: {
				label: /*$t*/ ctx[0]("PROFILE.OFFLINE.TOOLTIPS.LOGIN"),
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			h2 = element("h2");
			t0 = text(t0_value);
			t1 = space();
			div = element("div");
			create_component(tooltip.$$.fragment);
			attr(h2, "class", "text-center");
			attr(div, "class", "m-2 flex flex-col");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			append(h2, t0);
			insert(target, t1, anchor);
			insert(target, div, anchor);
			mount_component(tooltip, div, null);
			current = true;
		},
		p(ctx, dirty) {
			if ((!current || dirty & /*$t*/ 1) && t0_value !== (t0_value = /*$t*/ ctx[0]("PROFILE.OFFLINE.TITLE") + "")) set_data(t0, t0_value);
			const tooltip_changes = {};
			if (dirty & /*$t*/ 1) tooltip_changes.label = /*$t*/ ctx[0]("PROFILE.OFFLINE.TOOLTIPS.LOGIN");

			if (dirty & /*$$scope*/ 2048) {
				tooltip_changes.$$scope = { dirty, ctx };
			}

			tooltip.$set(tooltip_changes);
		},
		i(local) {
			if (current) return;
			transition_in(tooltip.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(tooltip.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			if (detaching) detach(div);
			destroy_component(tooltip);
		}
	};
}

// (28:2) {#if $user.uid}
function create_if_block(ctx) {
	let h1;
	let t0_value = /*$t*/ ctx[0]("PROFILE.ONLINE.TITLE") + "";
	let t0;
	let t1;
	let h3;
	let t2_value = /*$t*/ ctx[0]("PROFILE.ONLINE.SUBTITLE") + "";
	let t2;
	let t3;
	let img;
	let img_src_value;
	let t4;
	let div;
	let h20;
	let t5_value = /*$user*/ ctx[1].displayName + "";
	let t5;
	let t6;
	let h21;
	let t7_value = /*$user*/ ctx[1].email + "";
	let t7;
	let t8;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let each_1_anchor;
	let current;
	let each_value = /*btnsLogin*/ ctx[2];
	const get_key = ctx => /*name*/ ctx[8];

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			h1 = element("h1");
			t0 = text(t0_value);
			t1 = space();
			h3 = element("h3");
			t2 = text(t2_value);
			t3 = space();
			img = element("img");
			t4 = space();
			div = element("div");
			h20 = element("h2");
			t5 = text(t5_value);
			t6 = space();
			h21 = element("h2");
			t7 = text(t7_value);
			t8 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
			attr(h3, "class", "mb-5");
			attr(img, "class", "mx-auto rounded-lg");
			if (img.src !== (img_src_value = /*$user*/ ctx[1].photoURL)) attr(img, "src", img_src_value);
			attr(img, "alt", "user");
			attr(div, "class", "my-8");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			append(h1, t0);
			insert(target, t1, anchor);
			insert(target, h3, anchor);
			append(h3, t2);
			insert(target, t3, anchor);
			insert(target, img, anchor);
			insert(target, t4, anchor);
			insert(target, div, anchor);
			append(div, h20);
			append(h20, t5);
			append(div, t6);
			append(div, h21);
			append(h21, t7);
			insert(target, t8, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if ((!current || dirty & /*$t*/ 1) && t0_value !== (t0_value = /*$t*/ ctx[0]("PROFILE.ONLINE.TITLE") + "")) set_data(t0, t0_value);
			if ((!current || dirty & /*$t*/ 1) && t2_value !== (t2_value = /*$t*/ ctx[0]("PROFILE.ONLINE.SUBTITLE") + "")) set_data(t2, t2_value);

			if (!current || dirty & /*$user*/ 2 && img.src !== (img_src_value = /*$user*/ ctx[1].photoURL)) {
				attr(img, "src", img_src_value);
			}

			if ((!current || dirty & /*$user*/ 2) && t5_value !== (t5_value = /*$user*/ ctx[1].displayName + "")) set_data(t5, t5_value);
			if ((!current || dirty & /*$user*/ 2) && t7_value !== (t7_value = /*$user*/ ctx[1].email + "")) set_data(t7, t7_value);

			if (dirty & /*btnsLogin*/ 4) {
				each_value = /*btnsLogin*/ ctx[2];
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, each_1_anchor.parentNode, outro_and_destroy_block, create_each_block, each_1_anchor, get_each_context);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(h3);
			if (detaching) detach(t3);
			if (detaching) detach(img);
			if (detaching) detach(t4);
			if (detaching) detach(div);
			if (detaching) detach(t8);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d(detaching);
			}

			if (detaching) detach(each_1_anchor);
		}
	};
}

// (47:6) <Tooltip label={$t("PROFILE.OFFLINE.TOOLTIPS.LOGIN")}>
function create_default_slot_2(ctx) {
	let button;
	let current;
	button = new Button({ props: { prop: /*btnSignin*/ ctx[3] } });

	return {
		c() {
			create_component(button.$$.fragment);
		},
		m(target, anchor) {
			mount_component(button, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(button, detaching);
		}
	};
}

// (39:8) <Tooltip {label}>
function create_default_slot_1(ctx) {
	let button;
	let current;

	button = new Button({
			props: {
				prop: {
					icon: /*icon*/ ctx[5],
					style: /*style*/ ctx[6],
					onClick: /*onClick*/ ctx[7]
				}
			}
		});

	return {
		c() {
			create_component(button.$$.fragment);
		},
		m(target, anchor) {
			mount_component(button, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(button, detaching);
		}
	};
}

// (37:4) {#each btnsLogin as { label, icon, style, onClick, name }
function create_each_block(key_1, ctx) {
	let div;
	let tooltip;
	let t_1;
	let current;

	tooltip = new Tooltip({
			props: {
				label: /*label*/ ctx[4],
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		key: key_1,
		first: null,
		c() {
			div = element("div");
			create_component(tooltip.$$.fragment);
			t_1 = space();
			attr(div, "class", "m-2 flex flex-col");
			this.first = div;
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(tooltip, div, null);
			append(div, t_1);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const tooltip_changes = {};

			if (dirty & /*$$scope*/ 2048) {
				tooltip_changes.$$scope = { dirty, ctx };
			}

			tooltip.$set(tooltip_changes);
		},
		i(local) {
			if (current) return;
			transition_in(tooltip.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(tooltip.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(tooltip);
		}
	};
}

// (27:0) <Card>
function create_default_slot(ctx) {
	let current_block_type_index;
	let if_block;
	let t_1;
	let div;
	let feedback;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*$user*/ ctx[1].uid) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	feedback = new Feedback({});

	return {
		c() {
			if_block.c();
			t_1 = space();
			div = element("div");
			create_component(feedback.$$.fragment);
			attr(div, "class", "mt-5");
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, t_1, anchor);
			insert(target, div, anchor);
			mount_component(feedback, div, null);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(t_1.parentNode, t_1);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			transition_in(feedback.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			transition_out(feedback.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(t_1);
			if (detaching) detach(div);
			destroy_component(feedback);
		}
	};
}

function create_fragment(ctx) {
	let card;
	let t0;
	let div3;
	let div0;
	let h50;
	let t2;
	let switch_1;
	let t3;
	let div1;
	let h51;
	let t4_value = /*$t*/ ctx[0]("PROFILE.OPTIONS.LANGUAGE") + "";
	let t4;
	let t5;
	let langselect;
	let t6;
	let div2;
	let h52;
	let t7_value = /*$t*/ ctx[0]("PROFILE.OPTIONS.CURRENCY") + "";
	let t7;
	let t8;
	let currencyselect;
	let current;

	card = new Card({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	switch_1 = new Switch({});
	langselect = new LangSelect({});
	currencyselect = new CurrencySelect({});

	return {
		c() {
			create_component(card.$$.fragment);
			t0 = space();
			div3 = element("div");
			div0 = element("div");
			h50 = element("h5");
			h50.textContent = "Change the theme:";
			t2 = space();
			create_component(switch_1.$$.fragment);
			t3 = space();
			div1 = element("div");
			h51 = element("h5");
			t4 = text(t4_value);
			t5 = space();
			create_component(langselect.$$.fragment);
			t6 = space();
			div2 = element("div");
			h52 = element("h5");
			t7 = text(t7_value);
			t8 = space();
			create_component(currencyselect.$$.fragment);
			attr(h50, "class", "mb-1");
			attr(h51, "class", "mb-5");
			attr(div1, "class", "mt-5");
			attr(h52, "class", "mb-1");
			attr(div2, "class", "mt-5");
			attr(div3, "class", "sm:w-96 p-4 w-8/12 ");
		},
		m(target, anchor) {
			mount_component(card, target, anchor);
			insert(target, t0, anchor);
			insert(target, div3, anchor);
			append(div3, div0);
			append(div0, h50);
			append(div0, t2);
			mount_component(switch_1, div0, null);
			append(div3, t3);
			append(div3, div1);
			append(div1, h51);
			append(h51, t4);
			append(div1, t5);
			mount_component(langselect, div1, null);
			append(div3, t6);
			append(div3, div2);
			append(div2, h52);
			append(h52, t7);
			append(div2, t8);
			mount_component(currencyselect, div2, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const card_changes = {};

			if (dirty & /*$$scope, $user, $t*/ 2051) {
				card_changes.$$scope = { dirty, ctx };
			}

			card.$set(card_changes);
			if ((!current || dirty & /*$t*/ 1) && t4_value !== (t4_value = /*$t*/ ctx[0]("PROFILE.OPTIONS.LANGUAGE") + "")) set_data(t4, t4_value);
			if ((!current || dirty & /*$t*/ 1) && t7_value !== (t7_value = /*$t*/ ctx[0]("PROFILE.OPTIONS.CURRENCY") + "")) set_data(t7, t7_value);
		},
		i(local) {
			if (current) return;
			transition_in(card.$$.fragment, local);
			transition_in(switch_1.$$.fragment, local);
			transition_in(langselect.$$.fragment, local);
			transition_in(currencyselect.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(card.$$.fragment, local);
			transition_out(switch_1.$$.fragment, local);
			transition_out(langselect.$$.fragment, local);
			transition_out(currencyselect.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(card, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div3);
			destroy_component(switch_1);
			destroy_component(langselect);
			destroy_component(currencyselect);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $t;
	let $user;
	component_subscribe($$self, t, $$value => $$invalidate(0, $t = $$value));
	component_subscribe($$self, user, $$value => $$invalidate(1, $user = $$value));

	const btnsLogin = [
		{
			name: "SignOut",
			label: $t("PROFILE.ONLINE.TOOLTIPS.LOGOUT"),
			icon: "logout",
			style: "primary",
			onClick: actionSignOut
		}
	];

	const btnSignin = {
		icon: "login",
		style: "primary",
		onClick: signIn
	};

	return [$t, $user, btnsLogin, btnSignin];
}

class User extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default User;