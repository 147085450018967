import './ModalSavings.svelte.css';
/* src/lib/components/ModalSavings.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	add_flush_callback,
	append,
	attr,
	bind,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	set_data,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import Modal from "../atoms/Modal.svelte.js";
import { today } from "../../scripts/scripts.js";
import { t } from "../../i18n/i18n.js";
import Input from "../atoms/Input.svelte.js";
import Button from "../atoms/Button.svelte.js";
import Tooltip from "../atoms/Tooltip.svelte.js";
import Table from "../atoms/Table.svelte.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[15] = list[i].label;
	child_ctx[16] = list[i].icon;
	child_ctx[17] = list[i].style;
	child_ctx[18] = list[i].onClick;
	return child_ctx;
}

// (62:2) {:else}
function create_else_block(ctx) {
	let div;
	let button;
	let current;
	button = new Button({ props: { prop: /*btns*/ ctx[10][1] } });

	return {
		c() {
			div = element("div");
			create_component(button.$$.fragment);
			attr(div, "class", "float-right  mt-5 flex flex-row");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(button, div, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(button);
		}
	};
}

// (50:2) {#if savings.length > 0}
function create_if_block(ctx) {
	let div0;
	let t_1;
	let div1;
	let table;
	let current;
	let each_value = /*btns*/ ctx[10];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	table = new Table({
			props: {
				savings: /*savingsSorted*/ ctx[9],
				removeSaving: /*removeSaving*/ ctx[2],
				id: /*id*/ ctx[4]
			}
		});

	return {
		c() {
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t_1 = space();
			div1 = element("div");
			create_component(table.$$.fragment);
			attr(div0, "class", "float-right  mt-5 flex flex-row");
			attr(div1, "class", "mt-20 svelte-gm1d5i");
			toggle_class(div1, "hide", !/*historial*/ ctx[5]);
		},
		m(target, anchor) {
			insert(target, div0, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div0, null);
			}

			insert(target, t_1, anchor);
			insert(target, div1, anchor);
			mount_component(table, div1, null);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*btns*/ 1024) {
				each_value = /*btns*/ ctx[10];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div0, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			const table_changes = {};
			if (dirty & /*savingsSorted*/ 512) table_changes.savings = /*savingsSorted*/ ctx[9];
			if (dirty & /*removeSaving*/ 4) table_changes.removeSaving = /*removeSaving*/ ctx[2];
			if (dirty & /*id*/ 16) table_changes.id = /*id*/ ctx[4];
			table.$set(table_changes);

			if (dirty & /*historial*/ 32) {
				toggle_class(div1, "hide", !/*historial*/ ctx[5]);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(table.$$.fragment, local);
			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(table.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div0);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t_1);
			if (detaching) detach(div1);
			destroy_component(table);
		}
	};
}

// (53:8) <Tooltip {label}>
function create_default_slot_1(ctx) {
	let button;
	let t_1;
	let current;

	button = new Button({
			props: {
				prop: {
					icon: /*icon*/ ctx[16],
					style: /*style*/ ctx[17],
					onClick: /*onClick*/ ctx[18]
				}
			}
		});

	return {
		c() {
			create_component(button.$$.fragment);
			t_1 = space();
		},
		m(target, anchor) {
			mount_component(button, target, anchor);
			insert(target, t_1, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(button, detaching);
			if (detaching) detach(t_1);
		}
	};
}

// (52:6) {#each btns as { label, icon, style, onClick }}
function create_each_block(ctx) {
	let tooltip;
	let current;

	tooltip = new Tooltip({
			props: {
				label: /*label*/ ctx[15],
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(tooltip.$$.fragment);
		},
		m(target, anchor) {
			mount_component(tooltip, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const tooltip_changes = {};

			if (dirty & /*$$scope*/ 2097152) {
				tooltip_changes.$$scope = { dirty, ctx };
			}

			tooltip.$set(tooltip_changes);
		},
		i(local) {
			if (current) return;
			transition_in(tooltip.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(tooltip.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(tooltip, detaching);
		}
	};
}

// (41:0) <Modal {hideModal}>
function create_default_slot(ctx) {
	let h1;
	let t0;
	let t1;
	let input0;
	let updating_value;
	let t2;
	let input1;
	let updating_value_1;
	let t3;
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;

	function input0_value_binding(value) {
		/*input0_value_binding*/ ctx[12](value);
	}

	let input0_props = {
		label: /*$t*/ ctx[8]("SETTINGS.MODAL.ADD_SAVING"),
		type: "number"
	};

	if (/*amount*/ ctx[6] !== void 0) {
		input0_props.value = /*amount*/ ctx[6];
	}

	input0 = new Input({ props: input0_props });
	binding_callbacks.push(() => bind(input0, "value", input0_value_binding));

	function input1_value_binding(value) {
		/*input1_value_binding*/ ctx[13](value);
	}

	let input1_props = {
		label: /*$t*/ ctx[8]("SETTINGS.MODAL.DATE"),
		type: "date"
	};

	if (/*date*/ ctx[7] !== void 0) {
		input1_props.value = /*date*/ ctx[7];
	}

	input1 = new Input({ props: input1_props });
	binding_callbacks.push(() => bind(input1, "value", input1_value_binding));
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*savings*/ ctx[1].length > 0) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			h1 = element("h1");
			t0 = text(/*title*/ ctx[3]);
			t1 = space();
			create_component(input0.$$.fragment);
			t2 = space();
			create_component(input1.$$.fragment);
			t3 = space();
			if_block.c();
			if_block_anchor = empty();
			attr(h1, "class", "noselect text-2xl");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			append(h1, t0);
			insert(target, t1, anchor);
			mount_component(input0, target, anchor);
			insert(target, t2, anchor);
			mount_component(input1, target, anchor);
			insert(target, t3, anchor);
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (!current || dirty & /*title*/ 8) set_data(t0, /*title*/ ctx[3]);
			const input0_changes = {};
			if (dirty & /*$t*/ 256) input0_changes.label = /*$t*/ ctx[8]("SETTINGS.MODAL.ADD_SAVING");

			if (!updating_value && dirty & /*amount*/ 64) {
				updating_value = true;
				input0_changes.value = /*amount*/ ctx[6];
				add_flush_callback(() => updating_value = false);
			}

			input0.$set(input0_changes);
			const input1_changes = {};
			if (dirty & /*$t*/ 256) input1_changes.label = /*$t*/ ctx[8]("SETTINGS.MODAL.DATE");

			if (!updating_value_1 && dirty & /*date*/ 128) {
				updating_value_1 = true;
				input1_changes.value = /*date*/ ctx[7];
				add_flush_callback(() => updating_value_1 = false);
			}

			input1.$set(input1_changes);
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(input0.$$.fragment, local);
			transition_in(input1.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(input0.$$.fragment, local);
			transition_out(input1.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			destroy_component(input0, detaching);
			if (detaching) detach(t2);
			destroy_component(input1, detaching);
			if (detaching) detach(t3);
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function create_fragment(ctx) {
	let modal;
	let current;

	modal = new Modal({
			props: {
				hideModal: /*hideModal*/ ctx[0],
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(modal.$$.fragment);
		},
		m(target, anchor) {
			mount_component(modal, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const modal_changes = {};
			if (dirty & /*hideModal*/ 1) modal_changes.hideModal = /*hideModal*/ ctx[0];

			if (dirty & /*$$scope, historial, savingsSorted, removeSaving, id, savings, $t, date, amount, title*/ 2098174) {
				modal_changes.$$scope = { dirty, ctx };
			}

			modal.$set(modal_changes);
		},
		i(local) {
			if (current) return;
			transition_in(modal.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(modal.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(modal, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let savingsSorted;
	let $t;
	component_subscribe($$self, t, $$value => $$invalidate(8, $t = $$value));
	let { hideModal = true } = $$props;

	let { save = () => {
		
	} } = $$props;

	let { savings = [] } = $$props;

	let { removeSaving = () => {
		
	} } = $$props;

	let { title = "Title" } = $$props;
	let { id = "0" } = $$props;
	let historial = false;
	const toogleHistorial = () => $$invalidate(5, historial = !historial);
	let amount = 0;

	const btns = [
		{
			icon: "history",
			label: $t("SETTINGS.MODAL.TOOLTIPS.HISTORY"),
			style: "modal",
			onClick: toogleHistorial
		},
		{
			icon: "saving",
			label: $t("SETTINGS.MODAL.TOOLTIPS.SAVE"),
			style: "modal",
			onClick: () => {
				save(id, date, amount);
			}
		}
	];

	let date = today;

	function input0_value_binding(value) {
		amount = value;
		$$invalidate(6, amount);
	}

	function input1_value_binding(value) {
		date = value;
		$$invalidate(7, date);
	}

	$$self.$$set = $$props => {
		if ("hideModal" in $$props) $$invalidate(0, hideModal = $$props.hideModal);
		if ("save" in $$props) $$invalidate(11, save = $$props.save);
		if ("savings" in $$props) $$invalidate(1, savings = $$props.savings);
		if ("removeSaving" in $$props) $$invalidate(2, removeSaving = $$props.removeSaving);
		if ("title" in $$props) $$invalidate(3, title = $$props.title);
		if ("id" in $$props) $$invalidate(4, id = $$props.id);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*savings*/ 2) {
			$: $$invalidate(9, savingsSorted = savings.sort((a, b) => {
				a = a.today.split("-").join("");
				b = b.today.split("-").join("");
				return b - a;
			}));
		}
	};

	return [
		hideModal,
		savings,
		removeSaving,
		title,
		id,
		historial,
		amount,
		date,
		$t,
		savingsSorted,
		btns,
		save,
		input0_value_binding,
		input1_value_binding
	];
}

class ModalSavings extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			hideModal: 0,
			save: 11,
			savings: 1,
			removeSaving: 2,
			title: 3,
			id: 4
		});
	}
}

export default ModalSavings;