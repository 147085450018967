import './Landing.svelte.css';
/* src/lib/pages/Landing.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	is_function,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_store_value,
	space,
	text
} from "../../../_snowpack/pkg/svelte/internal.js";

import { landing } from "../../stores/landing.js";
import { t } from "../../i18n/i18n.js";

function create_fragment(ctx) {
	let div1;
	let img0;
	let img0_src_value;
	let t0;
	let h2;
	let t1_value = /*$t*/ ctx[1]("LANDING.DESCRIPTION") + "";
	let t1;
	let t2;
	let button0;
	let t3_value = /*$t*/ ctx[1]("LANDING.CALL_TO_ACTION") + "";
	let t3;
	let t4;
	let img1;
	let img1_src_value;
	let t5;
	let div0;
	let button1;
	let t6_value = /*$t*/ ctx[1]("FAQS.READ") + "";
	let t6;
	let mounted;
	let dispose;

	return {
		c() {
			div1 = element("div");
			img0 = element("img");
			t0 = space();
			h2 = element("h2");
			t1 = text(t1_value);
			t2 = space();
			button0 = element("button");
			t3 = text(t3_value);
			t4 = space();
			img1 = element("img");
			t5 = space();
			div0 = element("div");
			button1 = element("button");
			t6 = text(t6_value);
			attr(img0, "class", "rounded-lg w-32 svelte-xnb4zo");
			if (img0.src !== (img0_src_value = "/icons/gofire.svg")) attr(img0, "src", img0_src_value);
			attr(img0, "alt", "gofire logo");
			attr(h2, "class", "font-mono mt-14 svelte-xnb4zo");
			attr(img1, "class", "inline-block w-6 svelte-xnb4zo");
			if (img1.src !== (img1_src_value = "/icons/arrow.svg")) attr(img1, "src", img1_src_value);
			attr(img1, "alt", "launch app");
			attr(button0, "class", "mt-14 btn-start svelte-xnb4zo");
			attr(button1, "class", "mt-3 btn-guide svelte-xnb4zo");
			attr(div0, "class", "mb-12 svelte-xnb4zo");
			attr(div1, "class", "text-center gridding md:w-6/12 svelte-xnb4zo");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, img0);
			append(div1, t0);
			append(div1, h2);
			append(h2, t1);
			append(div1, t2);
			append(div1, button0);
			append(button0, t3);
			append(button0, t4);
			append(button0, img1);
			append(div1, t5);
			append(div1, div0);
			append(div0, button1);
			append(button1, t6);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler*/ ctx[4]),
					listen(div0, "click", function () {
						if (is_function(/*toogleGuide*/ ctx[0])) /*toogleGuide*/ ctx[0].apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;
			if (dirty & /*$t*/ 2 && t1_value !== (t1_value = /*$t*/ ctx[1]("LANDING.DESCRIPTION") + "")) set_data(t1, t1_value);
			if (dirty & /*$t*/ 2 && t3_value !== (t3_value = /*$t*/ ctx[1]("LANDING.CALL_TO_ACTION") + "")) set_data(t3, t3_value);
			if (dirty & /*$t*/ 2 && t6_value !== (t6_value = /*$t*/ ctx[1]("FAQS.READ") + "")) set_data(t6, t6_value);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $t;
	let $landing;
	component_subscribe($$self, t, $$value => $$invalidate(1, $t = $$value));
	component_subscribe($$self, landing, $$value => $$invalidate(2, $landing = $$value));
	let { toogleGuide = () => console.log("click") } = $$props;

	let { quitGuide = () => {
		
	} } = $$props;

	const click_handler = () => set_store_value(landing, $landing = false, $landing);

	$$self.$$set = $$props => {
		if ("toogleGuide" in $$props) $$invalidate(0, toogleGuide = $$props.toogleGuide);
		if ("quitGuide" in $$props) $$invalidate(3, quitGuide = $$props.quitGuide);
	};

	return [toogleGuide, $t, $landing, quitGuide, click_handler];
}

class Landing extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { toogleGuide: 0, quitGuide: 3 });
	}
}

export default Landing;