import './CurrencySelect.svelte.css';
/* src/lib/atoms/CurrencySelect.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	select_option,
	select_value,
	text
} from "../../../_snowpack/pkg/svelte/internal.js";

import currencyDict from "../../i18n/currencyDict.js";
import { currency } from "../../i18n/currency.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	return child_ctx;
}

// (13:4) {#each currencies as curr}
function create_each_block(ctx) {
	let option;
	let t_value = /*getCurrencies*/ ctx[2](/*curr*/ ctx[4]) + "";
	let t;
	let option_value_value;

	return {
		c() {
			option = element("option");
			t = text(t_value);
			option.__value = option_value_value = /*curr*/ ctx[4];
			option.value = option.__value;
		},
		m(target, anchor) {
			insert(target, option, anchor);
			append(option, t);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(option);
		}
	};
}

function create_fragment(ctx) {
	let select;
	let mounted;
	let dispose;
	let each_value = /*currencies*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			select = element("select");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(select, "placeholder", "currency");
			attr(select, "class", "svelte-111gomq");
			if (/*$currency*/ ctx[0] === void 0) add_render_callback(() => /*select_change_handler*/ ctx[3].call(select));
		},
		m(target, anchor) {
			insert(target, select, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(select, null);
			}

			select_option(select, /*$currency*/ ctx[0]);

			if (!mounted) {
				dispose = listen(select, "change", /*select_change_handler*/ ctx[3]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*currencies, getCurrencies*/ 6) {
				each_value = /*currencies*/ ctx[1];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*$currency, currencies*/ 3) {
				select_option(select, /*$currency*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(select);
			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $currency;
	component_subscribe($$self, currency, $$value => $$invalidate(0, $currency = $$value));
	const currencies = Object.keys(currencyDict).sort();

	const getCurrencies = (curr = "USD") => {
		let value = currencyDict[curr];
		let symbol = value.symbol;
		let name = value.name;
		return `${curr} | ${name} - ${symbol}`;
	};

	function select_change_handler() {
		$currency = select_value(this);
		currency.set($currency);
		$$invalidate(1, currencies);
	}

	return [$currency, currencies, getCurrencies, select_change_handler];
}

class CurrencySelect extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default CurrencySelect;