import './Input.svelte.css';
/* src/lib/atoms/Input.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_data,
	set_input_value,
	space,
	text,
	to_number
} from "../../../_snowpack/pkg/svelte/internal.js";

function create_if_block_2(ctx) {
	let input;
	let input_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			input = element("input");
			attr(input, "type", "text");
			attr(input, "class", input_class_value = "color input__" + /*style*/ ctx[1] + " " + " svelte-tfhjdb");
			attr(input, "placeholder", /*placeholder*/ ctx[2]);
		},
		m(target, anchor) {
			insert(target, input, anchor);
			set_input_value(input, /*value*/ ctx[0]);

			if (!mounted) {
				dispose = listen(input, "input", /*input_input_handler_2*/ ctx[7]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*style*/ 2 && input_class_value !== (input_class_value = "color input__" + /*style*/ ctx[1] + " " + " svelte-tfhjdb")) {
				attr(input, "class", input_class_value);
			}

			if (dirty & /*placeholder*/ 4) {
				attr(input, "placeholder", /*placeholder*/ ctx[2]);
			}

			if (dirty & /*value*/ 1 && input.value !== /*value*/ ctx[0]) {
				set_input_value(input, /*value*/ ctx[0]);
			}
		},
		d(detaching) {
			if (detaching) detach(input);
			mounted = false;
			dispose();
		}
	};
}

// (20:27) 
function create_if_block_1(ctx) {
	let input;
	let input_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			input = element("input");
			attr(input, "type", "date");
			attr(input, "class", input_class_value = "color input__" + /*style*/ ctx[1] + " svelte-tfhjdb");
			attr(input, "placeholder", /*placeholder*/ ctx[2]);
		},
		m(target, anchor) {
			insert(target, input, anchor);
			set_input_value(input, /*value*/ ctx[0]);

			if (!mounted) {
				dispose = listen(input, "input", /*input_input_handler_1*/ ctx[6]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*style*/ 2 && input_class_value !== (input_class_value = "color input__" + /*style*/ ctx[1] + " svelte-tfhjdb")) {
				attr(input, "class", input_class_value);
			}

			if (dirty & /*placeholder*/ 4) {
				attr(input, "placeholder", /*placeholder*/ ctx[2]);
			}

			if (dirty & /*value*/ 1) {
				set_input_value(input, /*value*/ ctx[0]);
			}
		},
		d(detaching) {
			if (detaching) detach(input);
			mounted = false;
			dispose();
		}
	};
}

// (13:1) {#if type === "number"}
function create_if_block(ctx) {
	let input;
	let input_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			input = element("input");
			attr(input, "type", "number");
			attr(input, "class", input_class_value = "color input__" + /*style*/ ctx[1] + " " + " svelte-tfhjdb");
			attr(input, "placeholder", /*placeholder*/ ctx[2]);
		},
		m(target, anchor) {
			insert(target, input, anchor);
			set_input_value(input, /*value*/ ctx[0]);

			if (!mounted) {
				dispose = listen(input, "input", /*input_input_handler*/ ctx[5]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*style*/ 2 && input_class_value !== (input_class_value = "color input__" + /*style*/ ctx[1] + " " + " svelte-tfhjdb")) {
				attr(input, "class", input_class_value);
			}

			if (dirty & /*placeholder*/ 4) {
				attr(input, "placeholder", /*placeholder*/ ctx[2]);
			}

			if (dirty & /*value*/ 1 && to_number(input.value) !== /*value*/ ctx[0]) {
				set_input_value(input, /*value*/ ctx[0]);
			}
		},
		d(detaching) {
			if (detaching) detach(input);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div;
	let h3;
	let t0;
	let t1;

	function select_block_type(ctx, dirty) {
		if (/*type*/ ctx[4] === "number") return create_if_block;
		if (/*type*/ ctx[4] === "date") return create_if_block_1;
		if (/*type*/ ctx[4] === "text") return create_if_block_2;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type && current_block_type(ctx);

	return {
		c() {
			div = element("div");
			h3 = element("h3");
			t0 = text(/*label*/ ctx[3]);
			t1 = space();
			if (if_block) if_block.c();
			attr(h3, "class", "mt-8 mb-1");
			attr(div, "class", "svelte-tfhjdb");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h3);
			append(h3, t0);
			append(div, t1);
			if (if_block) if_block.m(div, null);
		},
		p(ctx, [dirty]) {
			if (dirty & /*label*/ 8) set_data(t0, /*label*/ ctx[3]);

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if (if_block) if_block.d(1);
				if_block = current_block_type && current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);

			if (if_block) {
				if_block.d();
			}
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { style = "primary" } = $$props;
	let { placeholder = "0" } = $$props;
	let { value = "" } = $$props;
	let { label = "" } = $$props;
	let { type = "number" } = $$props;

	function input_input_handler() {
		value = to_number(this.value);
		$$invalidate(0, value);
	}

	function input_input_handler_1() {
		value = this.value;
		$$invalidate(0, value);
	}

	function input_input_handler_2() {
		value = this.value;
		$$invalidate(0, value);
	}

	$$self.$$set = $$props => {
		if ("style" in $$props) $$invalidate(1, style = $$props.style);
		if ("placeholder" in $$props) $$invalidate(2, placeholder = $$props.placeholder);
		if ("value" in $$props) $$invalidate(0, value = $$props.value);
		if ("label" in $$props) $$invalidate(3, label = $$props.label);
		if ("type" in $$props) $$invalidate(4, type = $$props.type);
	};

	return [
		value,
		style,
		placeholder,
		label,
		type,
		input_input_handler,
		input_input_handler_1,
		input_input_handler_2
	];
}

class Input extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			style: 1,
			placeholder: 2,
			value: 0,
			label: 3,
			type: 4
		});
	}
}

export default Input;