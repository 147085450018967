import './Goal.svelte.css';
/* src/lib/atoms/Goal.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	component_subscribe,
	create_in_transition,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "../../../_snowpack/pkg/svelte/internal.js";

import { fade } from "../../../_snowpack/pkg/svelte/transition.js";
import { t } from "../../i18n/i18n.js";
import { currency, formatNum } from "../../i18n/currency.js";

function create_else_block(ctx) {
	let p;
	let t0_value = /*$t*/ ctx[3]("HOME.GOAL") + "";
	let t0;
	let t1;
	let h6;
	let t2;

	return {
		c() {
			p = element("p");
			t0 = text(t0_value);
			t1 = space();
			h6 = element("h6");
			t2 = text(/*roadFormatted*/ ctx[0]);
			attr(p, "class", "euros text-xs text-center");
			attr(h6, "class", "euros text-xs text-center svelte-1gx6k9q");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t0);
			insert(target, t1, anchor);
			insert(target, h6, anchor);
			append(h6, t2);
		},
		p(ctx, dirty) {
			if (dirty & /*$t*/ 8 && t0_value !== (t0_value = /*$t*/ ctx[3]("HOME.GOAL") + "")) set_data(t0, t0_value);
			if (dirty & /*roadFormatted*/ 1) set_data(t2, /*roadFormatted*/ ctx[0]);
		},
		d(detaching) {
			if (detaching) detach(p);
			if (detaching) detach(t1);
			if (detaching) detach(h6);
		}
	};
}

// (19:2) {#if checkGoal()}
function create_if_block(ctx) {
	let p;
	let t0_value = /*$t*/ ctx[3]("HOME.TO") + " " + /*roadFormatted*/ ctx[0] + "";
	let t0;
	let t1;
	let h6;
	let t2_value = /*$t*/ ctx[3]("HOME.OF") + " " + /*goalFormatted*/ ctx[1] + "";
	let t2;

	return {
		c() {
			p = element("p");
			t0 = text(t0_value);
			t1 = space();
			h6 = element("h6");
			t2 = text(t2_value);
			attr(p, "class", "euros text-sm text-center");
			attr(h6, "class", "euros text-xs text-center svelte-1gx6k9q");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t0);
			insert(target, t1, anchor);
			insert(target, h6, anchor);
			append(h6, t2);
		},
		p(ctx, dirty) {
			if (dirty & /*$t, roadFormatted*/ 9 && t0_value !== (t0_value = /*$t*/ ctx[3]("HOME.TO") + " " + /*roadFormatted*/ ctx[0] + "")) set_data(t0, t0_value);
			if (dirty & /*$t, goalFormatted*/ 10 && t2_value !== (t2_value = /*$t*/ ctx[3]("HOME.OF") + " " + /*goalFormatted*/ ctx[1] + "")) set_data(t2, t2_value);
		},
		d(detaching) {
			if (detaching) detach(p);
			if (detaching) detach(t1);
			if (detaching) detach(h6);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let img;
	let img_src_value;
	let t_1;
	let show_if;
	let div_intro;

	function select_block_type(ctx, dirty) {
		if (show_if == null || dirty & /*checkGoal*/ 4) show_if = !!/*checkGoal*/ ctx[2]();
		if (show_if) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			div = element("div");
			img = element("img");
			t_1 = space();
			if_block.c();
			attr(img, "class", "m-auto svelte-1gx6k9q");
			if (img.src !== (img_src_value = "icons/goal.svg")) attr(img, "src", img_src_value);
			attr(img, "alt", "goal");
			attr(div, "class", "goal  float-right bg-red-500 p-5  svelte-1gx6k9q");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, img);
			append(div, t_1);
			if_block.m(div, null);
		},
		p(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		i(local) {
			if (!div_intro) {
				add_render_callback(() => {
					div_intro = create_in_transition(div, fade, {});
					div_intro.start();
				});
			}
		},
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			if_block.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let road;
	let checkGoal;
	let $t;
	component_subscribe($$self, t, $$value => $$invalidate(3, $t = $$value));
	let { goal = 0 } = $$props;
	let { balance = 0 } = $$props;
	let roadFormatted = "";
	let goalFormatted = "";

	$$self.$$set = $$props => {
		if ("goal" in $$props) $$invalidate(4, goal = $$props.goal);
		if ("balance" in $$props) $$invalidate(5, balance = $$props.balance);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*goal, balance*/ 48) {
			$: $$invalidate(6, road = goal - balance);
		}

		if ($$self.$$.dirty & /*goal, balance*/ 48) {
			$: $$invalidate(2, checkGoal = () => goal > balance);
		}

		if ($$self.$$.dirty & /*road, goal*/ 80) {
			$: currency.subscribe(val => {
				$$invalidate(0, roadFormatted = formatNum(road));
				$$invalidate(1, goalFormatted = formatNum(goal));
			});
		}
	};

	return [roadFormatted, goalFormatted, checkGoal, $t, goal, balance, road];
}

class Goal extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { goal: 4, balance: 5 });
	}
}

export default Goal;