import './Avatar.svelte.css';
/* src/lib/atoms/Avatar.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	empty,
	init,
	insert,
	is_function,
	listen,
	noop,
	safe_not_equal,
	space
} from "../../../_snowpack/pkg/svelte/internal.js";

import { landing } from "../../stores/landing.js";

function create_if_block(ctx) {
	let if_block_anchor;

	function select_block_type(ctx, dirty) {
		if (/*guide*/ ctx[3]) return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		d(detaching) {
			if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (13:1) {:else}
function create_else_block(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.innerHTML = `<img class="inline-block" src="/icons/help.svg" alt="help"/>`;
			attr(button, "class", "svelte-jj1egs");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", function () {
					if (is_function(/*toogleGuide*/ ctx[2])) /*toogleGuide*/ ctx[2].apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (9:1) {#if guide}
function create_if_block_1(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.innerHTML = `<img class="inline-block" src="/icons/close.svg" alt="close"/>`;
			attr(button, "class", "svelte-jj1egs");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", function () {
					if (is_function(/*toogleGuide*/ ctx[2])) /*toogleGuide*/ ctx[2].apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let t;
	let button;
	let img_1;
	let img_1_class_value;
	let img_1_src_value;
	let mounted;
	let dispose;
	let if_block = /*$landing*/ ctx[4] == false && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			t = space();
			button = element("button");
			img_1 = element("img");
			attr(img_1, "class", img_1_class_value = " inline-block w-12 h-12 rounded-full " + (/*img*/ ctx[0] === "icons/user.svg" ? "filter" : "") + " svelte-jj1egs");
			if (img_1.src !== (img_1_src_value = /*img*/ ctx[0])) attr(img_1, "src", img_1_src_value);
			attr(img_1, "alt", "Avatar");
			attr(button, "class", "svelte-jj1egs");
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, t, anchor);
			insert(target, button, anchor);
			append(button, img_1);

			if (!mounted) {
				dispose = listen(button, "click", function () {
					if (is_function(/*onClick*/ ctx[1])) /*onClick*/ ctx[1].apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;

			if (/*$landing*/ ctx[4] == false) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(t.parentNode, t);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*img*/ 1 && img_1_class_value !== (img_1_class_value = " inline-block w-12 h-12 rounded-full " + (/*img*/ ctx[0] === "icons/user.svg" ? "filter" : "") + " svelte-jj1egs")) {
				attr(img_1, "class", img_1_class_value);
			}

			if (dirty & /*img*/ 1 && img_1.src !== (img_1_src_value = /*img*/ ctx[0])) {
				attr(img_1, "src", img_1_src_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(t);
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $landing;
	component_subscribe($$self, landing, $$value => $$invalidate(4, $landing = $$value));
	let { img = "icons/user.svg" } = $$props;
	let { onClick = () => console.log("click avatar") } = $$props;

	let { toogleGuide = () => {
		
	} } = $$props;

	let { guide = false } = $$props;

	$$self.$$set = $$props => {
		if ("img" in $$props) $$invalidate(0, img = $$props.img);
		if ("onClick" in $$props) $$invalidate(1, onClick = $$props.onClick);
		if ("toogleGuide" in $$props) $$invalidate(2, toogleGuide = $$props.toogleGuide);
		if ("guide" in $$props) $$invalidate(3, guide = $$props.guide);
	};

	return [img, onClick, toogleGuide, guide, $landing];
}

class Avatar extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			img: 0,
			onClick: 1,
			toogleGuide: 2,
			guide: 3
		});
	}
}

export default Avatar;