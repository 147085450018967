/* src/lib/atoms/ChartDoughnut.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	component_subscribe,
	create_component,
	destroy_component,
	init,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import Doughnut from "../../../_snowpack/pkg/svelte-chartjs/src/Doughnut.svelte.js";
import { t } from "../../i18n/i18n.js";

function create_fragment(ctx) {
	let doughnut;
	let current;

	doughnut = new Doughnut({
			props: {
				data: /*data*/ ctx[0],
				options: /*options*/ ctx[1]
			}
		});

	return {
		c() {
			create_component(doughnut.$$.fragment);
		},
		m(target, anchor) {
			mount_component(doughnut, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const doughnut_changes = {};
			if (dirty & /*data*/ 1) doughnut_changes.data = /*data*/ ctx[0];
			if (dirty & /*options*/ 2) doughnut_changes.options = /*options*/ ctx[1];
			doughnut.$set(doughnut_changes);
		},
		i(local) {
			if (current) return;
			transition_in(doughnut.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(doughnut.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(doughnut, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $t;
	component_subscribe($$self, t, $$value => $$invalidate(3, $t = $$value));
	let { metrics = [80, 20] } = $$props;

	let { data = {
		labels: [$t("CHARTS.DOUGHNUT.SAVING"), $t("CHARTS.DOUGHNUT.PROFIT")],
		datasets: [
			{
				data: metrics,
				backgroundColor: ["hsl(3, 75%, 60%)", "hsl(200, 78%, 52%)"],
				hoverBackgroundColor: ["hsl(360, 71%, 73%)", "hsl(206, 85%, 76%)"]
			}
		]
	} } = $$props;

	let { options = { responsive: true } } = $$props;

	$$self.$$set = $$props => {
		if ("metrics" in $$props) $$invalidate(2, metrics = $$props.metrics);
		if ("data" in $$props) $$invalidate(0, data = $$props.data);
		if ("options" in $$props) $$invalidate(1, options = $$props.options);
	};

	return [data, options, metrics];
}

class ChartDoughnut extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { metrics: 2, data: 0, options: 1 });
	}
}

export default ChartDoughnut;