import './Grid.svelte.css';
/* src/lib/organisms/Grid.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_animation,
	create_component,
	create_in_transition,
	destroy_component,
	detach,
	element,
	empty,
	fix_and_outro_and_destroy_block,
	fix_position,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	outro_and_destroy_block,
	prevent_default,
	run_all,
	safe_not_equal,
	space,
	toggle_class,
	transition_in,
	transition_out,
	update_keyed_each
} from "../../../_snowpack/pkg/svelte/internal.js";

import Row from "../components/Row.svelte.js";
import { data } from "../../stores/data.js";
import { flip } from "../../../_snowpack/pkg/svelte/animate.js";
import Button from "../atoms/Button.svelte.js";
import { fade } from "../../../_snowpack/pkg/svelte/transition.js";

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[10] = list[i];
	return child_ctx;
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[10] = list[i];
	child_ctx[12] = i;
	return child_ctx;
}

// (69:25) 
function create_if_block_3(ctx) {
	let each_blocks = [];
	let each_1_lookup = new Map();
	let each_1_anchor;
	let current;
	let each_value_1 = /*$data*/ ctx[2];
	const get_key = ctx => /*prop*/ ctx[10].id;

	for (let i = 0; i < each_value_1.length; i += 1) {
		let child_ctx = get_each_context_1(ctx, each_value_1, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block_1(key, child_ctx));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*edit, $data, data*/ 5) {
				each_value_1 = /*$data*/ ctx[2];
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value_1, each_1_lookup, each_1_anchor.parentNode, outro_and_destroy_block, create_each_block_1, each_1_anchor, get_each_context_1);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d(detaching);
			}

			if (detaching) detach(each_1_anchor);
		}
	};
}

// (31:0) {#if $data && edit}
function create_if_block(ctx) {
	let each_blocks = [];
	let each_1_lookup = new Map();
	let each_1_anchor;
	let current;
	let each_value = /*$data*/ ctx[2];
	const get_key = ctx => /*prop*/ ctx[10].id;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*data, $data, hovering, dragstart, drop, edit*/ 31) {
				each_value = /*$data*/ ctx[2];
				group_outros();
				for (let i = 0; i < each_blocks.length; i += 1) each_blocks[i].r();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, each_1_anchor.parentNode, fix_and_outro_and_destroy_block, create_each_block, each_1_anchor, get_each_context);
				for (let i = 0; i < each_blocks.length; i += 1) each_blocks[i].a();
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d(detaching);
			}

			if (detaching) detach(each_1_anchor);
		}
	};
}

// (70:2) {#each $data as prop (prop.id)}
function create_each_block_1(key_1, ctx) {
	let div;
	let row;
	let t;
	let div_intro;
	let current;

	row = new Row({
			props: {
				edit: /*edit*/ ctx[0],
				prop: /*prop*/ ctx[10],
				data
			}
		});

	return {
		key: key_1,
		first: null,
		c() {
			div = element("div");
			create_component(row.$$.fragment);
			t = space();
			this.first = div;
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(row, div, null);
			append(div, t);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const row_changes = {};
			if (dirty & /*edit*/ 1) row_changes.edit = /*edit*/ ctx[0];
			if (dirty & /*$data*/ 4) row_changes.prop = /*prop*/ ctx[10];
			row.$set(row_changes);
		},
		i(local) {
			if (current) return;
			transition_in(row.$$.fragment, local);

			if (!div_intro) {
				add_render_callback(() => {
					div_intro = create_in_transition(div, fade, {});
					div_intro.start();
				});
			}

			current = true;
		},
		o(local) {
			transition_out(row.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(row);
		}
	};
}

// (47:8) {#if index !== 0}
function create_if_block_2(ctx) {
	let button;
	let current;

	function func() {
		return /*func*/ ctx[8](/*index*/ ctx[12]);
	}

	button = new Button({
			props: {
				prop: { icon: "up", style: "move", onClick: func }
			}
		});

	return {
		c() {
			create_component(button.$$.fragment);
		},
		m(target, anchor) {
			mount_component(button, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const button_changes = {};
			if (dirty & /*$data*/ 4) button_changes.prop = { icon: "up", style: "move", onClick: func };
			button.$set(button_changes);
		},
		i(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(button, detaching);
		}
	};
}

// (57:8) {#if index !== $data.length - 1}
function create_if_block_1(ctx) {
	let button;
	let current;

	function func_1() {
		return /*func_1*/ ctx[9](/*index*/ ctx[12]);
	}

	button = new Button({
			props: {
				prop: {
					icon: "down",
					style: "move",
					onClick: func_1
				}
			}
		});

	return {
		c() {
			create_component(button.$$.fragment);
		},
		m(target, anchor) {
			mount_component(button, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const button_changes = {};

			if (dirty & /*$data*/ 4) button_changes.prop = {
				icon: "down",
				style: "move",
				onClick: func_1
			};

			button.$set(button_changes);
		},
		i(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(button, detaching);
		}
	};
}

// (32:2) {#each $data as prop, index (prop.id)}
function create_each_block(key_1, ctx) {
	let div2;
	let div0;
	let row;
	let div0_draggable_value;
	let t0;
	let div1;
	let t1;
	let t2;
	let div2_intro;
	let rect;
	let stop_animation = noop;
	let current;
	let mounted;
	let dispose;

	row = new Row({
			props: {
				edit: /*edit*/ ctx[0],
				prop: /*prop*/ ctx[10],
				data
			}
		});

	function dragstart_handler(...args) {
		return /*dragstart_handler*/ ctx[5](/*index*/ ctx[12], ...args);
	}

	function drop_handler(...args) {
		return /*drop_handler*/ ctx[6](/*index*/ ctx[12], ...args);
	}

	function dragenter_handler() {
		return /*dragenter_handler*/ ctx[7](/*index*/ ctx[12]);
	}

	let if_block0 = /*index*/ ctx[12] !== 0 && create_if_block_2(ctx);
	let if_block1 = /*index*/ ctx[12] !== /*$data*/ ctx[2].length - 1 && create_if_block_1(ctx);

	return {
		key: key_1,
		first: null,
		c() {
			div2 = element("div");
			div0 = element("div");
			create_component(row.$$.fragment);
			t0 = space();
			div1 = element("div");
			if (if_block0) if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			t2 = space();
			attr(div0, "class", "list-item svelte-1wuvhrt");
			attr(div0, "draggable", div0_draggable_value = true);
			attr(div0, "ondragover", "return false");
			toggle_class(div0, "is-active", /*hovering*/ ctx[1] === /*index*/ ctx[12]);
			attr(div1, "class", "move_btns svelte-1wuvhrt");
			attr(div2, "class", "relative");
			this.first = div2;
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			mount_component(row, div0, null);
			append(div2, t0);
			append(div2, div1);
			if (if_block0) if_block0.m(div1, null);
			append(div1, t1);
			if (if_block1) if_block1.m(div1, null);
			append(div2, t2);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div0, "dragstart", dragstart_handler),
					listen(div0, "drop", prevent_default(drop_handler)),
					listen(div0, "dragenter", dragenter_handler)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const row_changes = {};
			if (dirty & /*edit*/ 1) row_changes.edit = /*edit*/ ctx[0];
			if (dirty & /*$data*/ 4) row_changes.prop = /*prop*/ ctx[10];
			row.$set(row_changes);

			if (dirty & /*hovering, $data*/ 6) {
				toggle_class(div0, "is-active", /*hovering*/ ctx[1] === /*index*/ ctx[12]);
			}

			if (/*index*/ ctx[12] !== 0) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*$data*/ 4) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div1, t1);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*index*/ ctx[12] !== /*$data*/ ctx[2].length - 1) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*$data*/ 4) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div1, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}
		},
		r() {
			rect = div2.getBoundingClientRect();
		},
		f() {
			fix_position(div2);
			stop_animation();
		},
		a() {
			stop_animation();
			stop_animation = create_animation(div2, rect, flip, {});
		},
		i(local) {
			if (current) return;
			transition_in(row.$$.fragment, local);
			transition_in(if_block0);
			transition_in(if_block1);

			if (!div2_intro) {
				add_render_callback(() => {
					div2_intro = create_in_transition(div2, fade, {});
					div2_intro.start();
				});
			}

			current = true;
		},
		o(local) {
			transition_out(row.$$.fragment, local);
			transition_out(if_block0);
			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			destroy_component(row);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block, create_if_block_3];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*$data*/ ctx[2] && /*edit*/ ctx[0]) return 0;
		if (/*$data*/ ctx[2] && !/*edit*/ ctx[0]) return 1;
		return -1;
	}

	if (~(current_block_type_index = select_block_type(ctx, -1))) {
		if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	}

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (~current_block_type_index) {
				if_blocks[current_block_type_index].m(target, anchor);
			}

			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if (~current_block_type_index) {
					if_blocks[current_block_type_index].p(ctx, dirty);
				}
			} else {
				if (if_block) {
					group_outros();

					transition_out(if_blocks[previous_block_index], 1, 1, () => {
						if_blocks[previous_block_index] = null;
					});

					check_outros();
				}

				if (~current_block_type_index) {
					if_block = if_blocks[current_block_type_index];

					if (!if_block) {
						if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
						if_block.c();
					} else {
						if_block.p(ctx, dirty);
					}

					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				} else {
					if_block = null;
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (~current_block_type_index) {
				if_blocks[current_block_type_index].d(detaching);
			}

			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $data;
	component_subscribe($$self, data, $$value => $$invalidate(2, $data = $$value));
	let { edit = false } = $$props;
	let hovering = -1;

	const drop = (event, target) => {
		event.dataTransfer.dropEffect = "move";
		const start = parseInt(event.dataTransfer.getData("text/plain"));
		const newTracklist = $data;

		if (start < target) {
			newTracklist.splice(target + 1, 0, newTracklist[start]);
			newTracklist.splice(start, 1);
		} else {
			newTracklist.splice(target, 0, newTracklist[start]);
			newTracklist.splice(start + 1, 1);
		}

		data.set(newTracklist);
		$$invalidate(1, hovering = -1);
	};

	const dragstart = (event, i) => {
		event.dataTransfer.effectAllowed = "move";
		event.dataTransfer.dropEffect = "move";
		const start = i;
		event.dataTransfer.setData("text/plain", start);
	};

	const dragstart_handler = (index, event) => dragstart(event, index);
	const drop_handler = (index, event) => drop(event, index);
	const dragenter_handler = index => $$invalidate(1, hovering = index);
	const func = index => data.moveUp(index);
	const func_1 = index => data.moveDown(index);

	$$self.$$set = $$props => {
		if ("edit" in $$props) $$invalidate(0, edit = $$props.edit);
	};

	return [
		edit,
		hovering,
		$data,
		drop,
		dragstart,
		dragstart_handler,
		drop_handler,
		dragenter_handler,
		func,
		func_1
	];
}

class Grid extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { edit: 0 });
	}
}

export default Grid;