/* src/lib/pages/Chart.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import ChartDoughnut from "../atoms/ChartDoughnut.svelte.js";
import Card from "../atoms/Card.svelte.js";
import { data } from "../../stores/data.js";
import { round } from "../../scripts/scripts.js";
import { t } from "../../i18n/i18n.js";

function create_default_slot(ctx) {
	let h1;
	let t0_value = /*$t*/ ctx[0]("CHARTS.TITLE") + "";
	let t0;
	let t1;
	let h3;
	let t2_value = /*$t*/ ctx[0]("CHARTS.SUBTITLE") + "";
	let t2;

	return {
		c() {
			h1 = element("h1");
			t0 = text(t0_value);
			t1 = space();
			h3 = element("h3");
			t2 = text(t2_value);
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			append(h1, t0);
			insert(target, t1, anchor);
			insert(target, h3, anchor);
			append(h3, t2);
		},
		p(ctx, dirty) {
			if (dirty & /*$t*/ 1 && t0_value !== (t0_value = /*$t*/ ctx[0]("CHARTS.TITLE") + "")) set_data(t0, t0_value);
			if (dirty & /*$t*/ 1 && t2_value !== (t2_value = /*$t*/ ctx[0]("CHARTS.SUBTITLE") + "")) set_data(t2, t2_value);
		},
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(h3);
		}
	};
}

function create_fragment(ctx) {
	let card;
	let t_1;
	let chartdoughnut;
	let current;

	card = new Card({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	chartdoughnut = new ChartDoughnut({ props: { metrics: /*metrics*/ ctx[1] } });

	return {
		c() {
			create_component(card.$$.fragment);
			t_1 = space();
			create_component(chartdoughnut.$$.fragment);
		},
		m(target, anchor) {
			mount_component(card, target, anchor);
			insert(target, t_1, anchor);
			mount_component(chartdoughnut, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const card_changes = {};

			if (dirty & /*$$scope, $t*/ 17) {
				card_changes.$$scope = { dirty, ctx };
			}

			card.$set(card_changes);
		},
		i(local) {
			if (current) return;
			transition_in(card.$$.fragment, local);
			transition_in(chartdoughnut.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(card.$$.fragment, local);
			transition_out(chartdoughnut.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(card, detaching);
			if (detaching) detach(t_1);
			destroy_component(chartdoughnut, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $t;
	component_subscribe($$self, t, $$value => $$invalidate(0, $t = $$value));
	let revenue = 0;
	let saving = 0;

	data.subscribe(val => {
		if (val) {
			revenue = data.balance() - data.revenue() - data.detectNoSavings();
			saving = data.balance() - revenue;
		}
	});

	let metrics = [round(saving, { returnZero: true }), round(revenue, { returnZero: true })];
	return [$t, metrics];
}

class Chart extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Chart;