import './Guide.svelte.css';
/* src/lib/components/Guide.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "../../../_snowpack/pkg/svelte/internal.js";

import { t } from "../../i18n/i18n.js";

function create_fragment(ctx) {
	let h1;
	let t0_value = /*$t*/ ctx[0]("FAQS.TITLE") + "";
	let t0;
	let t1;
	let div1;
	let h20;
	let t2_value = /*$t*/ ctx[0]("FAQS.FAQ_1.TITLE") + "";
	let t2;
	let t3;
	let p0;
	let t4_value = /*$t*/ ctx[0]("FAQS.FAQ_1.DESCRIPTION") + "";
	let t4;
	let t5;
	let div0;
	let t6;
	let div3;
	let h21;
	let t7_value = /*$t*/ ctx[0]("FAQS.FAQ_2.TITLE") + "";
	let t7;
	let t8;
	let p1;
	let t9_value = /*$t*/ ctx[0]("FAQS.FAQ_2.DESCRIPTION") + "";
	let t9;
	let t10;
	let div2;
	let t11;
	let div5;
	let h22;
	let t12_value = /*$t*/ ctx[0]("FAQS.FAQ_3.TITLE") + "";
	let t12;
	let t13;
	let p2;
	let t14_value = /*$t*/ ctx[0]("FAQS.FAQ_3.DESCRIPTION_1") + "";
	let t14;
	let t15;
	let span;
	let t16;
	let t17_value = /*$t*/ ctx[0]("FAQS.FAQ_3.DESCRIPTION_2") + "";
	let t17;
	let t18;
	let div4;

	return {
		c() {
			h1 = element("h1");
			t0 = text(t0_value);
			t1 = space();
			div1 = element("div");
			h20 = element("h2");
			t2 = text(t2_value);
			t3 = space();
			p0 = element("p");
			t4 = text(t4_value);
			t5 = space();
			div0 = element("div");
			div0.innerHTML = `<img loading="lazy" class="gif svelte-omx7y8" src="/gif/add_goal.gif" alt="add goal"/>`;
			t6 = space();
			div3 = element("div");
			h21 = element("h2");
			t7 = text(t7_value);
			t8 = space();
			p1 = element("p");
			t9 = text(t9_value);
			t10 = space();
			div2 = element("div");
			div2.innerHTML = `<img loading="lazy" class="gif svelte-omx7y8" src="/gif/create_invest.gif" alt="gif create invest"/>`;
			t11 = space();
			div5 = element("div");
			h22 = element("h2");
			t12 = text(t12_value);
			t13 = space();
			p2 = element("p");
			t14 = text(t14_value);
			t15 = space();
			span = element("span");
			span.innerHTML = `<img class="w-8 inline-block" src="/icons/saving.svg" alt=""/>`;
			t16 = space();
			t17 = text(t17_value);
			t18 = space();
			div4 = element("div");
			div4.innerHTML = `<img loading="lazy" class="gif svelte-omx7y8" src="/gif/add_savings.gif" alt="gif add saving "/>`;
			attr(h1, "class", "text-center mb-6");
			attr(h20, "class", "text-left svelte-omx7y8");
			attr(p0, "class", "svelte-omx7y8");
			attr(div0, "class", "gridding svelte-omx7y8");
			attr(div1, "class", "text-left w-8/12 m-12");
			attr(h21, "class", "text-left svelte-omx7y8");
			attr(p1, "class", "svelte-omx7y8");
			attr(div2, "class", "gridding svelte-omx7y8");
			attr(div3, "class", "text-left w-8/12 m-12");
			attr(h22, "class", "text-left svelte-omx7y8");
			attr(p2, "class", "svelte-omx7y8");
			attr(div4, "class", "gridding svelte-omx7y8");
			attr(div5, "class", "text-left w-8/12 m-12 ");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			append(h1, t0);
			insert(target, t1, anchor);
			insert(target, div1, anchor);
			append(div1, h20);
			append(h20, t2);
			append(div1, t3);
			append(div1, p0);
			append(p0, t4);
			append(div1, t5);
			append(div1, div0);
			insert(target, t6, anchor);
			insert(target, div3, anchor);
			append(div3, h21);
			append(h21, t7);
			append(div3, t8);
			append(div3, p1);
			append(p1, t9);
			append(div3, t10);
			append(div3, div2);
			insert(target, t11, anchor);
			insert(target, div5, anchor);
			append(div5, h22);
			append(h22, t12);
			append(div5, t13);
			append(div5, p2);
			append(p2, t14);
			append(p2, t15);
			append(p2, span);
			append(p2, t16);
			append(p2, t17);
			append(div5, t18);
			append(div5, div4);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$t*/ 1 && t0_value !== (t0_value = /*$t*/ ctx[0]("FAQS.TITLE") + "")) set_data(t0, t0_value);
			if (dirty & /*$t*/ 1 && t2_value !== (t2_value = /*$t*/ ctx[0]("FAQS.FAQ_1.TITLE") + "")) set_data(t2, t2_value);
			if (dirty & /*$t*/ 1 && t4_value !== (t4_value = /*$t*/ ctx[0]("FAQS.FAQ_1.DESCRIPTION") + "")) set_data(t4, t4_value);
			if (dirty & /*$t*/ 1 && t7_value !== (t7_value = /*$t*/ ctx[0]("FAQS.FAQ_2.TITLE") + "")) set_data(t7, t7_value);
			if (dirty & /*$t*/ 1 && t9_value !== (t9_value = /*$t*/ ctx[0]("FAQS.FAQ_2.DESCRIPTION") + "")) set_data(t9, t9_value);
			if (dirty & /*$t*/ 1 && t12_value !== (t12_value = /*$t*/ ctx[0]("FAQS.FAQ_3.TITLE") + "")) set_data(t12, t12_value);
			if (dirty & /*$t*/ 1 && t14_value !== (t14_value = /*$t*/ ctx[0]("FAQS.FAQ_3.DESCRIPTION_1") + "")) set_data(t14, t14_value);
			if (dirty & /*$t*/ 1 && t17_value !== (t17_value = /*$t*/ ctx[0]("FAQS.FAQ_3.DESCRIPTION_2") + "")) set_data(t17, t17_value);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(div1);
			if (detaching) detach(t6);
			if (detaching) detach(div3);
			if (detaching) detach(t11);
			if (detaching) detach(div5);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $t;
	component_subscribe($$self, t, $$value => $$invalidate(0, $t = $$value));
	return [$t];
}

class Guide extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Guide;