import './Button.svelte.css';
/* src/lib/atoms/Button.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	bubble,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	null_to_empty,
	run_all,
	safe_not_equal,
	toggle_class
} from "../../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div;
	let button;
	let img;
	let img_src_value;
	let div_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			button = element("button");
			img = element("img");
			if (img.src !== (img_src_value = `icons/${/*icon*/ ctx[1]}.svg`)) attr(img, "src", img_src_value);
			attr(img, "alt", /*icon*/ ctx[1]);
			attr(img, "class", "svelte-mi9fm0");
			toggle_class(img, "selected__icon", /*current*/ ctx[0] === /*icon*/ ctx[1]);
			attr(div, "class", div_class_value = "" + (null_to_empty(/*style*/ ctx[2]) + " svelte-mi9fm0"));
			toggle_class(div, "selected", /*current*/ ctx[0] === /*icon*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, button);
			append(button, img);

			if (!mounted) {
				dispose = [
					listen(button, "click", /*onClick*/ ctx[3]),
					listen(div, "click", /*click_handler*/ ctx[5])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*current, icon*/ 3) {
				toggle_class(img, "selected__icon", /*current*/ ctx[0] === /*icon*/ ctx[1]);
			}

			if (dirty & /*current, icon*/ 3) {
				toggle_class(div, "selected", /*current*/ ctx[0] === /*icon*/ ctx[1]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { prop = {
		icon: "menu",
		style: "primary",
		onClick: () => console.log("click")
	} } = $$props;

	let { current = "profile" } = $$props;
	let { icon, style, onClick } = prop;

	function click_handler(event) {
		bubble($$self, event);
	}

	$$self.$$set = $$props => {
		if ("prop" in $$props) $$invalidate(4, prop = $$props.prop);
		if ("current" in $$props) $$invalidate(0, current = $$props.current);
	};

	return [current, icon, style, onClick, prop, click_handler];
}

class Button extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { prop: 4, current: 0 });
	}
}

export default Button;