/* src/lib/components/Balance.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import { formatNum, currency } from "../../i18n/currency.js";
import Goal from "../atoms/Goal.svelte.js";
import Card from "../atoms/Card.svelte.js";
import { user } from "../../stores/user.js";
import { goal } from "../../stores/goal.js";
import { t } from "../../i18n/i18n.js";

function create_default_slot(ctx) {
	let h1;
	let t0_value = /*$t*/ ctx[3]("HOME.HI") + " " + /*name*/ ctx[2] + "";
	let t0;
	let t1;
	let h30;
	let t2_value = /*$t*/ ctx[3]("HOME.SUBTITLE") + "";
	let t2;
	let t3;
	let h31;
	let t4_value = /*$t*/ ctx[3]("HOME.BALANCE") + "";
	let t4;
	let t5;
	let p;
	let t6;
	let t7;
	let goal_1;
	let current;

	goal_1 = new Goal({
			props: {
				balance: /*balance*/ ctx[0],
				goal: /*$goal*/ ctx[4]
			}
		});

	return {
		c() {
			h1 = element("h1");
			t0 = text(t0_value);
			t1 = space();
			h30 = element("h3");
			t2 = text(t2_value);
			t3 = space();
			h31 = element("h3");
			t4 = text(t4_value);
			t5 = space();
			p = element("p");
			t6 = text(/*balanceFormatted*/ ctx[1]);
			t7 = space();
			create_component(goal_1.$$.fragment);
			attr(h31, "class", "mt-8");
			attr(p, "class", "euros");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			append(h1, t0);
			insert(target, t1, anchor);
			insert(target, h30, anchor);
			append(h30, t2);
			insert(target, t3, anchor);
			insert(target, h31, anchor);
			append(h31, t4);
			insert(target, t5, anchor);
			insert(target, p, anchor);
			append(p, t6);
			insert(target, t7, anchor);
			mount_component(goal_1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if ((!current || dirty & /*$t, name*/ 12) && t0_value !== (t0_value = /*$t*/ ctx[3]("HOME.HI") + " " + /*name*/ ctx[2] + "")) set_data(t0, t0_value);
			if ((!current || dirty & /*$t*/ 8) && t2_value !== (t2_value = /*$t*/ ctx[3]("HOME.SUBTITLE") + "")) set_data(t2, t2_value);
			if ((!current || dirty & /*$t*/ 8) && t4_value !== (t4_value = /*$t*/ ctx[3]("HOME.BALANCE") + "")) set_data(t4, t4_value);
			if (!current || dirty & /*balanceFormatted*/ 2) set_data(t6, /*balanceFormatted*/ ctx[1]);
			const goal_1_changes = {};
			if (dirty & /*balance*/ 1) goal_1_changes.balance = /*balance*/ ctx[0];
			if (dirty & /*$goal*/ 16) goal_1_changes.goal = /*$goal*/ ctx[4];
			goal_1.$set(goal_1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(goal_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(goal_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(h30);
			if (detaching) detach(t3);
			if (detaching) detach(h31);
			if (detaching) detach(t5);
			if (detaching) detach(p);
			if (detaching) detach(t7);
			destroy_component(goal_1, detaching);
		}
	};
}

function create_fragment(ctx) {
	let card;
	let current;

	card = new Card({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(card.$$.fragment);
		},
		m(target, anchor) {
			mount_component(card, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const card_changes = {};

			if (dirty & /*$$scope, balance, $goal, balanceFormatted, $t, name*/ 95) {
				card_changes.$$scope = { dirty, ctx };
			}

			card.$set(card_changes);
		},
		i(local) {
			if (current) return;
			transition_in(card.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(card.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(card, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let name;
	let $user;
	let $t;
	let $goal;
	component_subscribe($$self, user, $$value => $$invalidate(5, $user = $$value));
	component_subscribe($$self, t, $$value => $$invalidate(3, $t = $$value));
	component_subscribe($$self, goal, $$value => $$invalidate(4, $goal = $$value));
	let { balance = 0 } = $$props;
	let balanceFormatted = "";

	$$self.$$set = $$props => {
		if ("balance" in $$props) $$invalidate(0, balance = $$props.balance);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$user*/ 32) {
			$: $$invalidate(2, name = $user.displayName
			? $user.displayName.split(" ")[0]
			: "crack");
		}

		if ($$self.$$.dirty & /*balance*/ 1) {
			$: currency.subscribe(val => {
				$$invalidate(1, balanceFormatted = formatNum(balance));
			});
		}
	};

	return [balance, balanceFormatted, name, $t, $goal, $user];
}

class Balance extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { balance: 0 });
	}
}

export default Balance;