import './Table.svelte.css';
/* src/lib/atoms/Table.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import Button from "./Button.svelte.js";
import { formatBasic } from "../../scripts/scripts.js";
import { t } from "../../i18n/i18n.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[5] = list[i].today;
	child_ctx[6] = list[i].amount;
	child_ctx[7] = list[i].id_saving;
	return child_ctx;
}

// (19:4) {#each savings as { today, amount, id_saving }}
function create_each_block(ctx) {
	let tr;
	let td0;
	let t0_value = /*today*/ ctx[5] + "";
	let t0;
	let t1;
	let td1;
	let t2_value = formatBasic(/*amount*/ ctx[6]) + "";
	let t2;
	let t3;
	let td2;
	let button;
	let t4;
	let current;

	function func() {
		return /*func*/ ctx[4](/*id_saving*/ ctx[7]);
	}

	button = new Button({
			props: {
				prop: {
					icon: "delete",
					style: "tertiary",
					onClick: func
				}
			}
		});

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			t1 = space();
			td1 = element("td");
			t2 = text(t2_value);
			t3 = space();
			td2 = element("td");
			create_component(button.$$.fragment);
			t4 = space();
			attr(td0, "class", "text-xs svelte-1q78vq1");
			attr(td1, "class", "svelte-1q78vq1");
			attr(td2, "class", "svelte-1q78vq1");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, t1);
			append(tr, td1);
			append(td1, t2);
			append(tr, t3);
			append(tr, td2);
			mount_component(button, td2, null);
			append(tr, t4);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if ((!current || dirty & /*savings*/ 2) && t0_value !== (t0_value = /*today*/ ctx[5] + "")) set_data(t0, t0_value);
			if ((!current || dirty & /*savings*/ 2) && t2_value !== (t2_value = formatBasic(/*amount*/ ctx[6]) + "")) set_data(t2, t2_value);
			const button_changes = {};

			if (dirty & /*removeSaving, id, savings*/ 7) button_changes.prop = {
				icon: "delete",
				style: "tertiary",
				onClick: func
			};

			button.$set(button_changes);
		},
		i(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(tr);
			destroy_component(button);
		}
	};
}

function create_fragment(ctx) {
	let table;
	let thead;
	let tr;
	let th0;
	let t0_value = /*$t*/ ctx[3]("SETTINGS.MODAL.TABLE.DATE") + "";
	let t0;
	let t1;
	let th1;
	let t2_value = /*$t*/ ctx[3]("SETTINGS.MODAL.TABLE.AMOUNT") + "";
	let t2;
	let t3;
	let tbody;
	let current;
	let each_value = /*savings*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			table = element("table");
			thead = element("thead");
			tr = element("tr");
			th0 = element("th");
			t0 = text(t0_value);
			t1 = space();
			th1 = element("th");
			t2 = text(t2_value);
			t3 = space();
			tbody = element("tbody");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(th0, "class", "svelte-1q78vq1");
			attr(th1, "class", "svelte-1q78vq1");
			attr(table, "class", "text-center w-full");
		},
		m(target, anchor) {
			insert(target, table, anchor);
			append(table, thead);
			append(thead, tr);
			append(tr, th0);
			append(th0, t0);
			append(tr, t1);
			append(tr, th1);
			append(th1, t2);
			append(table, t3);
			append(table, tbody);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(tbody, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if ((!current || dirty & /*$t*/ 8) && t0_value !== (t0_value = /*$t*/ ctx[3]("SETTINGS.MODAL.TABLE.DATE") + "")) set_data(t0, t0_value);
			if ((!current || dirty & /*$t*/ 8) && t2_value !== (t2_value = /*$t*/ ctx[3]("SETTINGS.MODAL.TABLE.AMOUNT") + "")) set_data(t2, t2_value);

			if (dirty & /*removeSaving, id, savings, formatBasic*/ 7) {
				each_value = /*savings*/ ctx[1];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(tbody, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(table);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $t;
	component_subscribe($$self, t, $$value => $$invalidate(3, $t = $$value));

	let { removeSaving = () => {
		
	} } = $$props;

	let { savings = [] } = $$props;
	let { id = 0 } = $$props;
	const func = id_saving => removeSaving(id, id_saving);

	$$self.$$set = $$props => {
		if ("removeSaving" in $$props) $$invalidate(0, removeSaving = $$props.removeSaving);
		if ("savings" in $$props) $$invalidate(1, savings = $$props.savings);
		if ("id" in $$props) $$invalidate(2, id = $$props.id);
	};

	return [removeSaving, savings, id, $t, func];
}

class Table extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { removeSaving: 0, savings: 1, id: 2 });
	}
}

export default Table;