import './Revenue.svelte.css';
/* src/lib/components/Revenue.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text,
	toggle_class
} from "../../../_snowpack/pkg/svelte/internal.js";

import { formatPercent } from "../../scripts/scripts.js";
import { t } from "../../i18n/i18n.js";
import { currency, formatNum } from "../../i18n/currency.js";

function create_fragment(ctx) {
	let div1;
	let h6;
	let t1;
	let div0;
	let p0;
	let t2;
	let t3;
	let p1;
	let t4_value = formatPercent(isNaN(/*percent*/ ctx[0]) ? 0 : /*percent*/ ctx[0]) + "";
	let t4;

	return {
		c() {
			div1 = element("div");
			h6 = element("h6");
			h6.textContent = `${/*title*/ ctx[2]}`;
			t1 = space();
			div0 = element("div");
			p0 = element("p");
			t2 = text(/*revenueFormatted*/ ctx[1]);
			t3 = space();
			p1 = element("p");
			t4 = text(t4_value);
			attr(h6, "class", "mb-1");
			attr(p0, "class", "euros");
			attr(p1, "class", "percent svelte-au9ifu");
			attr(div0, "class", "data svelte-au9ifu");
			attr(div1, "class", "w-64 m-3 relative m-6 svelte-au9ifu");
			toggle_class(div1, "hightlight", /*hightlight*/ ctx[3]);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, h6);
			append(div1, t1);
			append(div1, div0);
			append(div0, p0);
			append(p0, t2);
			append(div0, t3);
			append(div0, p1);
			append(p1, t4);
		},
		p(ctx, [dirty]) {
			if (dirty & /*revenueFormatted*/ 2) set_data(t2, /*revenueFormatted*/ ctx[1]);
			if (dirty & /*percent*/ 1 && t4_value !== (t4_value = formatPercent(isNaN(/*percent*/ ctx[0]) ? 0 : /*percent*/ ctx[0]) + "")) set_data(t4, t4_value);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $t;
	component_subscribe($$self, t, $$value => $$invalidate(6, $t = $$value));
	let { revenue = 0 } = $$props;
	let { percent = 0 } = $$props;

	let { props = {
		title: $t("HOME.PROFIT"),
		hightlight: true
	} } = $$props;

	let { title, hightlight } = props;
	let revenueFormatted = "";

	$$self.$$set = $$props => {
		if ("revenue" in $$props) $$invalidate(4, revenue = $$props.revenue);
		if ("percent" in $$props) $$invalidate(0, percent = $$props.percent);
		if ("props" in $$props) $$invalidate(5, props = $$props.props);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*revenue*/ 16) {
			$: currency.subscribe(val => {
				$$invalidate(1, revenueFormatted = formatNum(revenue));
			});
		}
	};

	return [percent, revenueFormatted, title, hightlight, revenue, props];
}

class Revenue extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { revenue: 4, percent: 0, props: 5 });
	}
}

export default Revenue;