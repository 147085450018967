import './Switch.svelte.css';
/* src/lib/atoms/Switch.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	space
} from "../../../_snowpack/pkg/svelte/internal.js";

import { theme } from "../../stores/theme.js";

function create_fragment(ctx) {
	let input;
	let t0;
	let label;
	let t1;
	let div;
	let mounted;
	let dispose;

	return {
		c() {
			input = element("input");
			t0 = space();
			label = element("label");
			label.innerHTML = `<span class="toggle--label-background svelte-gcn7jf"></span>`;
			t1 = space();
			div = element("div");
			attr(input, "data-testid", "input");
			attr(input, "type", "checkbox");
			attr(input, "id", "toggle");
			attr(input, "class", "toggle--checkbox svelte-gcn7jf");
			attr(label, "for", "toggle");
			attr(label, "class", "toggle--label svelte-gcn7jf");
			attr(div, "class", "background svelte-gcn7jf");
		},
		m(target, anchor) {
			insert(target, input, anchor);
			input.checked = /*checked*/ ctx[0];
			insert(target, t0, anchor);
			insert(target, label, anchor);
			insert(target, t1, anchor);
			insert(target, div, anchor);

			if (!mounted) {
				dispose = listen(input, "change", /*input_change_handler*/ ctx[1]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*checked*/ 1) {
				input.checked = /*checked*/ ctx[0];
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(input);
			if (detaching) detach(t0);
			if (detaching) detach(label);
			if (detaching) detach(t1);
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $theme;
	component_subscribe($$self, theme, $$value => $$invalidate(2, $theme = $$value));
	const selectTheme = () => $theme === "light" ? false : true;
	let checked = selectTheme();

	function input_change_handler() {
		checked = this.checked;
		$$invalidate(0, checked);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*checked*/ 1) {
			$: checked ? theme.set("dark") : theme.set("light");
		}
	};

	return [checked, input_change_handler];
}

class Switch extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Switch;