/* src/lib/pages/Home.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import Balance from "../components/Balance.svelte.js";
import Grid from "../organisms/Grid.svelte.js";
import Revenue from "../components/Revenue.svelte.js";
import { data } from "../../stores/data.js";

function create_fragment(ctx) {
	let balance_1;
	let t0;
	let revenue_1;
	let t1;
	let grid;
	let current;
	balance_1 = new Balance({ props: { balance: /*balance*/ ctx[0] } });

	revenue_1 = new Revenue({
			props: {
				revenue: /*revenue*/ ctx[1],
				percent: /*percent*/ ctx[2]
			}
		});

	grid = new Grid({});

	return {
		c() {
			create_component(balance_1.$$.fragment);
			t0 = space();
			create_component(revenue_1.$$.fragment);
			t1 = space();
			create_component(grid.$$.fragment);
		},
		m(target, anchor) {
			mount_component(balance_1, target, anchor);
			insert(target, t0, anchor);
			mount_component(revenue_1, target, anchor);
			insert(target, t1, anchor);
			mount_component(grid, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const balance_1_changes = {};
			if (dirty & /*balance*/ 1) balance_1_changes.balance = /*balance*/ ctx[0];
			balance_1.$set(balance_1_changes);
			const revenue_1_changes = {};
			if (dirty & /*revenue*/ 2) revenue_1_changes.revenue = /*revenue*/ ctx[1];
			if (dirty & /*percent*/ 4) revenue_1_changes.percent = /*percent*/ ctx[2];
			revenue_1.$set(revenue_1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(balance_1.$$.fragment, local);
			transition_in(revenue_1.$$.fragment, local);
			transition_in(grid.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(balance_1.$$.fragment, local);
			transition_out(revenue_1.$$.fragment, local);
			transition_out(grid.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(balance_1, detaching);
			if (detaching) detach(t0);
			destroy_component(revenue_1, detaching);
			if (detaching) detach(t1);
			destroy_component(grid, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let balance = 0;
	let revenue = 0;
	let percent = 0;

	data.subscribe(val => {
		if (val) {
			$$invalidate(0, balance = data.balance());
			$$invalidate(1, revenue = data.balance() - data.revenue() - data.detectNoSavings());
			$$invalidate(2, percent = (data.balance() - data.revenue() - data.detectNoSavings()) / data.revenue());
		}
	});

	return [balance, revenue, percent];
}

class Home extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Home;