/* src/App.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	set_store_value,
	space,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import Home from "./lib/pages/Home.svelte.js";
import User from "./lib/pages/User.svelte.js";
import Menu from "./lib/components/Menu.svelte.js";
import Settings from "./lib/pages/Settings.svelte.js";
import Navbar from "./lib/components/Navbar.svelte.js";
import Chart from "./lib/pages/Chart.svelte.js";
import { landing } from "./stores/landing.js";
import Landing from "./lib/pages/Landing.svelte.js";
import Guide from "./lib/components/Guide.svelte.js";
import Switch from "./lib/atoms/Switch.svelte.js";

function create_else_block(ctx) {
	let switch_instance;
	let t;
	let menu;
	let current;
	var switch_value = /*page*/ ctx[3];

	function switch_props(ctx) {
		return {};
	}

	if (switch_value) {
		switch_instance = new switch_value(switch_props(ctx));
	}

	menu = new Menu({
			props: {
				current: /*current*/ ctx[0],
				selectedBtn: /*selectedBtn*/ ctx[6]
			}
		});

	return {
		c() {
			if (switch_instance) create_component(switch_instance.$$.fragment);
			t = space();
			create_component(menu.$$.fragment);
		},
		m(target, anchor) {
			if (switch_instance) {
				mount_component(switch_instance, target, anchor);
			}

			insert(target, t, anchor);
			mount_component(menu, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (switch_value !== (switch_value = /*page*/ ctx[3])) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = new switch_value(switch_props(ctx));
					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, t.parentNode, t);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				
			}

			const menu_changes = {};
			if (dirty & /*current*/ 1) menu_changes.current = /*current*/ ctx[0];
			menu.$set(menu_changes);
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			transition_in(menu.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			transition_out(menu.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (switch_instance) destroy_component(switch_instance, detaching);
			if (detaching) detach(t);
			destroy_component(menu, detaching);
		}
	};
}

// (37:19) 
function create_if_block_1(ctx) {
	let landing_1;
	let current;

	landing_1 = new Landing({
			props: { toogleGuide: /*toogleGuide*/ ctx[4] }
		});

	return {
		c() {
			create_component(landing_1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(landing_1, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(landing_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(landing_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(landing_1, detaching);
		}
	};
}

// (35:0) {#if guide}
function create_if_block(ctx) {
	let guide_1;
	let current;
	guide_1 = new Guide({});

	return {
		c() {
			create_component(guide_1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(guide_1, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(guide_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(guide_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(guide_1, detaching);
		}
	};
}

function create_fragment(ctx) {
	let navbar;
	let t;
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;

	navbar = new Navbar({
			props: {
				toogleGuide: /*toogleGuide*/ ctx[4],
				guide: /*guide*/ ctx[2],
				quitGuide: /*quitGuide*/ ctx[5],
				landing: /*$landing*/ ctx[1]
			}
		});

	const if_block_creators = [create_if_block, create_if_block_1, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*guide*/ ctx[2]) return 0;
		if (/*$landing*/ ctx[1]) return 1;
		return 2;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			create_component(navbar.$$.fragment);
			t = space();
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			mount_component(navbar, target, anchor);
			insert(target, t, anchor);
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const navbar_changes = {};
			if (dirty & /*guide*/ 4) navbar_changes.guide = /*guide*/ ctx[2];
			if (dirty & /*$landing*/ 2) navbar_changes.landing = /*$landing*/ ctx[1];
			navbar.$set(navbar_changes);
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(navbar.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(navbar.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			destroy_component(navbar, detaching);
			if (detaching) detach(t);
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let page;
	let $landing;
	component_subscribe($$self, landing, $$value => $$invalidate(1, $landing = $$value));
	let current = "home";
	let guide = false;

	const toogleGuide = () => {
		$$invalidate(2, guide = !guide);
		set_store_value(landing, $landing = false, $landing);
	};

	const quitGuide = () => $$invalidate(2, guide = false);

	const selectedBtn = icon => {
		$$invalidate(0, current = icon);
	};

	const pages = {
		home: Home,
		tune: User,
		lightning: Settings,
		chart: Chart
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$landing*/ 2) {
			$: if ($landing === false) {
				$$invalidate(0, current = "home");
			}
		}

		if ($$self.$$.dirty & /*current*/ 1) {
			$: $$invalidate(3, page = pages[current]);
		}
	};

	return [current, $landing, guide, page, toogleGuide, quitGuide, selectedBtn];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;