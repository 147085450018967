import './Feedback.svelte.css';
/* src/lib/atoms/Feedback.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	text
} from "../../../_snowpack/pkg/svelte/internal.js";

import { t } from "../../i18n/i18n.js";

function create_fragment(ctx) {
	let div;
	let a;
	let t_1_value = /*$t*/ ctx[0]("PROFILE.OPTIONS.CONTACT") + "";
	let t_1;

	return {
		c() {
			div = element("div");
			a = element("a");
			t_1 = text(t_1_value);
			attr(a, "class", "typeform-share button svelte-1uudmzb");
			attr(a, "href", "https://form.typeform.com/to/x2B9zltX?typeform-medium=embed-snippet");
			attr(a, "data-mode", "popup");
			set_style(a, "display", "inline-block");
			set_style(a, "text-decoration", "none");
			set_style(a, "color", "white");
			set_style(a, "cursor", "pointer");
			set_style(a, "font-family", "Helvetica,Arial,sans-serif");
			set_style(a, "font-size", "20px");
			set_style(a, "line-height", "50px");
			set_style(a, "text-align", "center");
			set_style(a, "margin", "0");
			set_style(a, "height", "50px");
			set_style(a, "padding", "0px 33px");
			set_style(a, "border-radius", "25px");
			set_style(a, "max-width", "100%");
			set_style(a, "white-space", "nowrap");
			set_style(a, "overflow", "hidden");
			set_style(a, "text-overflow", "ellipsis");
			set_style(a, "font-weight", "bold");
			set_style(a, "-webkit-font-smoothing", "antialiased");
			set_style(a, "-moz-osx-font-smoothing", "grayscale");
			attr(a, "data-size", "100");
			attr(a, "target", "_blank");
			set_style(div, "text-align", "center");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, a);
			append(a, t_1);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$t*/ 1 && t_1_value !== (t_1_value = /*$t*/ ctx[0]("PROFILE.OPTIONS.CONTACT") + "")) set_data(t_1, t_1_value);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $t;
	component_subscribe($$self, t, $$value => $$invalidate(0, $t = $$value));

	(function () {
		var qs,
			js,
			q,
			s,
			d = document,
			gi = d.getElementById,
			ce = d.createElement,
			gt = d.getElementsByTagName,
			id = "typef_orm_share",
			b = "https://embed.typeform.com/";

		if (!gi.call(d, id)) {
			js = ce.call(d, "script");
			js.id = id;
			js.src = b + "embed.js";
			q = gt.call(d, "script")[0];
		}
	})();

	return [$t];
}

class Feedback extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Feedback;