import './Row.svelte.css';
/* src/lib/components/Row.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	space,
	text,
	to_number,
	toggle_class,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import { formatPercent } from "../../scripts/scripts.js";
import ModalSavings from "./ModalSavings.svelte.js";
import Button from "../atoms/Button.svelte.js";
import Tooltip from "../atoms/Tooltip.svelte.js";
import { t } from "../../i18n/i18n.js";
import { currency, formatNum } from "../../i18n/currency.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[25] = list[i];
	return child_ctx;
}

// (101:2) {:else}
function create_else_block(ctx) {
	let h6;
	let t0;
	let t1;
	let div;
	let p;
	let t2;
	let t3;
	let show_if = isFinite(/*percent*/ ctx[10]);
	let if_block = show_if && create_if_block_3(ctx);

	return {
		c() {
			h6 = element("h6");
			t0 = text(/*title*/ ctx[5]);
			t1 = space();
			div = element("div");
			p = element("p");
			t2 = text(/*numberFormatted*/ ctx[7]);
			t3 = space();
			if (if_block) if_block.c();
			attr(h6, "class", "mb-1");
			attr(p, "class", "euros");
			attr(div, "class", "data svelte-sa9pw7");
		},
		m(target, anchor) {
			insert(target, h6, anchor);
			append(h6, t0);
			insert(target, t1, anchor);
			insert(target, div, anchor);
			append(div, p);
			append(p, t2);
			append(div, t3);
			if (if_block) if_block.m(div, null);
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 32) set_data(t0, /*title*/ ctx[5]);
			if (dirty & /*numberFormatted*/ 128) set_data(t2, /*numberFormatted*/ ctx[7]);
			if (dirty & /*percent*/ 1024) show_if = isFinite(/*percent*/ ctx[10]);

			if (show_if) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_3(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h6);
			if (detaching) detach(t1);
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

// (71:2) {#if edit}
function create_if_block_1(ctx) {
	let div2;
	let input0;
	let t0;
	let div0;
	let input1;
	let t1;
	let show_if = isFinite(/*percent*/ ctx[10]);
	let t2;
	let div1;
	let current;
	let mounted;
	let dispose;
	let if_block = show_if && create_if_block_2(ctx);
	let each_value = /*btns*/ ctx[15];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div2 = element("div");
			input0 = element("input");
			t0 = space();
			div0 = element("div");
			input1 = element("input");
			t1 = space();
			if (if_block) if_block.c();
			t2 = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(input0, "name", "title");
			attr(input0, "class", "title w-64 svelte-sa9pw7");
			attr(input0, "type", "text");
			attr(input1, "name", "number");
			attr(input1, "type", "number");
			attr(input1, "class", "svelte-sa9pw7");
			attr(div0, "class", "data svelte-sa9pw7");
			attr(div1, "class", " flex flex-row float-right");
			attr(div2, "draggable", "true");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, input0);
			set_input_value(input0, /*title*/ ctx[5]);
			append(div2, t0);
			append(div2, div0);
			append(div0, input1);
			set_input_value(input1, /*number*/ ctx[4]);
			append(div0, t1);
			if (if_block) if_block.m(div0, null);
			append(div2, t2);
			append(div2, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div1, null);
			}

			current = true;

			if (!mounted) {
				dispose = [
					listen(input0, "change", /*changeName*/ ctx[13]),
					listen(input0, "input", /*input0_input_handler*/ ctx[18]),
					listen(input1, "change", /*changeNumber*/ ctx[14]),
					listen(input1, "input", /*input1_input_handler*/ ctx[19])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*title*/ 32 && input0.value !== /*title*/ ctx[5]) {
				set_input_value(input0, /*title*/ ctx[5]);
			}

			if (dirty & /*number*/ 16 && to_number(input1.value) !== /*number*/ ctx[4]) {
				set_input_value(input1, /*number*/ ctx[4]);
			}

			if (dirty & /*percent*/ 1024) show_if = isFinite(/*percent*/ ctx[10]);

			if (show_if) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_2(ctx);
					if_block.c();
					if_block.m(div0, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*btns*/ 32768) {
				each_value = /*btns*/ ctx[15];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div1, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			if (if_block) if_block.d();
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (106:6) {#if isFinite(percent)}
function create_if_block_3(ctx) {
	let p;
	let t_1_value = formatPercent(/*percent*/ ctx[10]) + "";
	let t_1;

	return {
		c() {
			p = element("p");
			t_1 = text(t_1_value);
			attr(p, "class", " percent svelte-sa9pw7");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t_1);
		},
		p(ctx, dirty) {
			if (dirty & /*percent*/ 1024 && t_1_value !== (t_1_value = formatPercent(/*percent*/ ctx[10]) + "")) set_data(t_1, t_1_value);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (87:8) {#if isFinite(percent)}
function create_if_block_2(ctx) {
	let p;
	let t_1_value = formatPercent(/*percent*/ ctx[10]) + "";
	let t_1;

	return {
		c() {
			p = element("p");
			t_1 = text(t_1_value);
			attr(p, "class", " percent svelte-sa9pw7");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t_1);
		},
		p(ctx, dirty) {
			if (dirty & /*percent*/ 1024 && t_1_value !== (t_1_value = formatPercent(/*percent*/ ctx[10]) + "")) set_data(t_1, t_1_value);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (93:10) <Tooltip label={btn.label}>
function create_default_slot(ctx) {
	let div;
	let button;
	let t_1;
	let current;
	button = new Button({ props: { prop: /*btn*/ ctx[25] } });

	return {
		c() {
			div = element("div");
			create_component(button.$$.fragment);
			t_1 = space();
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(button, div, null);
			insert(target, t_1, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(button);
			if (detaching) detach(t_1);
		}
	};
}

// (92:8) {#each btns as btn}
function create_each_block(ctx) {
	let tooltip;
	let current;

	tooltip = new Tooltip({
			props: {
				label: /*btn*/ ctx[25].label,
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(tooltip.$$.fragment);
		},
		m(target, anchor) {
			mount_component(tooltip, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const tooltip_changes = {};

			if (dirty & /*$$scope*/ 268435456) {
				tooltip_changes.$$scope = { dirty, ctx };
			}

			tooltip.$set(tooltip_changes);
		},
		i(local) {
			if (current) return;
			transition_in(tooltip.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(tooltip.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(tooltip, detaching);
		}
	};
}

// (111:2) {#if saving.length > 0 && !edit}
function create_if_block(ctx) {
	let h3;
	let t_1_value = /*$t*/ ctx[9]("HOME.PROFITABILITY") + " " + /*revenueFormatted*/ ctx[8] + "";
	let t_1;

	return {
		c() {
			h3 = element("h3");
			t_1 = text(t_1_value);
			attr(h3, "class", "mt-1 revenue text-sm");
		},
		m(target, anchor) {
			insert(target, h3, anchor);
			append(h3, t_1);
		},
		p(ctx, dirty) {
			if (dirty & /*$t, revenueFormatted*/ 768 && t_1_value !== (t_1_value = /*$t*/ ctx[9]("HOME.PROFITABILITY") + " " + /*revenueFormatted*/ ctx[8] + "")) set_data(t_1, t_1_value);
		},
		d(detaching) {
			if (detaching) detach(h3);
		}
	};
}

function create_fragment(ctx) {
	let modalsavings;
	let t0;
	let div;
	let current_block_type_index;
	let if_block0;
	let t1;
	let current;

	modalsavings = new ModalSavings({
			props: {
				hideModal: /*hideModal*/ ctx[0],
				title: /*title*/ ctx[5],
				id: /*id*/ ctx[11],
				save: /*data*/ ctx[2].saving,
				savings: /*prop*/ ctx[3].saving,
				removeSaving: /*data*/ ctx[2].removeSaving
			}
		});

	const if_block_creators = [create_if_block_1, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*edit*/ ctx[1]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	let if_block1 = /*saving*/ ctx[12].length > 0 && !/*edit*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			create_component(modalsavings.$$.fragment);
			t0 = space();
			div = element("div");
			if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			attr(div, "class", "w-64 m-3 relative svelte-sa9pw7");
			toggle_class(div, "hightlight", /*hightlight*/ ctx[6]);
		},
		m(target, anchor) {
			mount_component(modalsavings, target, anchor);
			insert(target, t0, anchor);
			insert(target, div, anchor);
			if_blocks[current_block_type_index].m(div, null);
			append(div, t1);
			if (if_block1) if_block1.m(div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const modalsavings_changes = {};
			if (dirty & /*hideModal*/ 1) modalsavings_changes.hideModal = /*hideModal*/ ctx[0];
			if (dirty & /*title*/ 32) modalsavings_changes.title = /*title*/ ctx[5];
			if (dirty & /*data*/ 4) modalsavings_changes.save = /*data*/ ctx[2].saving;
			if (dirty & /*prop*/ 8) modalsavings_changes.savings = /*prop*/ ctx[3].saving;
			if (dirty & /*data*/ 4) modalsavings_changes.removeSaving = /*data*/ ctx[2].removeSaving;
			modalsavings.$set(modalsavings_changes);
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block0 = if_blocks[current_block_type_index];

				if (!if_block0) {
					if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block0.c();
				} else {
					if_block0.p(ctx, dirty);
				}

				transition_in(if_block0, 1);
				if_block0.m(div, t1);
			}

			if (/*saving*/ ctx[12].length > 0 && !/*edit*/ ctx[1]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(div, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (dirty & /*hightlight*/ 64) {
				toggle_class(div, "hightlight", /*hightlight*/ ctx[6]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(modalsavings.$$.fragment, local);
			transition_in(if_block0);
			current = true;
		},
		o(local) {
			transition_out(modalsavings.$$.fragment, local);
			transition_out(if_block0);
			current = false;
		},
		d(detaching) {
			destroy_component(modalsavings, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div);
			if_blocks[current_block_type_index].d();
			if (if_block1) if_block1.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let getSaving;
	let revenue;
	let percent;
	let $t;
	component_subscribe($$self, t, $$value => $$invalidate(9, $t = $$value));
	let { edit = false } = $$props;
	let { data = [] } = $$props;

	let { prop = {
		id: "9c72c81f-480d-4575-bdff-45e50afc7a33",
		title: "Total Title",
		number: 0,
		hightlight: false,
		saving: []
	} } = $$props;

	let { id, title, number, hightlight, saving } = prop;
	let { hideModal = true } = $$props;
	const toogleModal = () => $$invalidate(0, hideModal = !hideModal);

	const actions = {
		remove: () => data.remove(id),
		changeName: () => data.change(id, "title", title),
		changeNumber: () => data.change(id, "number", number),
		changeHigh: () => data.change(id, "hightlight", hightlight),
		toogleHigh: () => {
			$$invalidate(6, hightlight = !hightlight);
			changeHigh();
		}
	};

	const { changeName, changeNumber, changeHigh, remove, toogleHigh } = actions;

	const btns = [
		{
			icon: "delete",
			style: "tertiary",
			onClick: remove,
			label: $t("SETTINGS.TOOLTIPS.REMOVE")
		},
		{
			icon: "highlight",
			style: "tertiary",
			onClick: toogleHigh,
			label: $t("SETTINGS.TOOLTIPS.HIGHLIGHT")
		},
		{
			icon: "saving",
			style: "tertiary",
			onClick: toogleModal,
			label: $t("SETTINGS.TOOLTIPS.SAVING")
		}
	];

	let numberFormatted = "";
	let revenueFormatted = "";

	function input0_input_handler() {
		title = this.value;
		$$invalidate(5, title);
	}

	function input1_input_handler() {
		number = to_number(this.value);
		$$invalidate(4, number);
	}

	$$self.$$set = $$props => {
		if ("edit" in $$props) $$invalidate(1, edit = $$props.edit);
		if ("data" in $$props) $$invalidate(2, data = $$props.data);
		if ("prop" in $$props) $$invalidate(3, prop = $$props.prop);
		if ("hideModal" in $$props) $$invalidate(0, hideModal = $$props.hideModal);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*number, getSaving*/ 65552) {
			$: $$invalidate(17, revenue = number - getSaving);
		}

		if ($$self.$$.dirty & /*number, getSaving*/ 65552) {
			$: $$invalidate(10, percent = (number - getSaving) / getSaving);
		}

		if ($$self.$$.dirty & /*number, revenue*/ 131088) {
			$: currency.subscribe(val => {
				$$invalidate(7, numberFormatted = formatNum(number));
				$$invalidate(8, revenueFormatted = formatNum(revenue));
			});
		}
	};

	$: $$invalidate(16, getSaving = saving.reduce((acc, crt) => acc + crt.amount, 0));

	return [
		hideModal,
		edit,
		data,
		prop,
		number,
		title,
		hightlight,
		numberFormatted,
		revenueFormatted,
		$t,
		percent,
		id,
		saving,
		changeName,
		changeNumber,
		btns,
		getSaving,
		revenue,
		input0_input_handler,
		input1_input_handler
	];
}

class Row extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { edit: 1, data: 2, prop: 3, hideModal: 0 });
	}
}

export default Row;