import './Tooltip.svelte.css';
/* src/lib/atoms/Tooltip.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_slot,
	detach,
	element,
	init,
	insert,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out,
	update_slot
} from "../../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div;
	let t0;
	let span;
	let t1;
	let current;
	const default_slot_template = /*#slots*/ ctx[2].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[1], null);

	return {
		c() {
			div = element("div");
			if (default_slot) default_slot.c();
			t0 = space();
			span = element("span");
			t1 = text(/*label*/ ctx[0]);
			attr(span, "class", "tooltiptext svelte-117us15");
			attr(div, "class", "tooltip svelte-117us15");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (default_slot) {
				default_slot.m(div, null);
			}

			append(div, t0);
			append(div, span);
			append(span, t1);
			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 2)) {
					update_slot(default_slot, default_slot_template, ctx, /*$$scope*/ ctx[1], dirty, null, null);
				}
			}

			if (!current || dirty & /*label*/ 1) set_data(t1, /*label*/ ctx[0]);
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { label = "Soy un tooltip" } = $$props;

	$$self.$$set = $$props => {
		if ("label" in $$props) $$invalidate(0, label = $$props.label);
		if ("$$scope" in $$props) $$invalidate(1, $$scope = $$props.$$scope);
	};

	return [label, $$scope, slots];
}

class Tooltip extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { label: 0 });
	}
}

export default Tooltip;