import './Modal.svelte.css';
/* src/lib/atoms/Modal.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	attr,
	create_component,
	create_slot,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	null_to_empty,
	safe_not_equal,
	space,
	transition_in,
	transition_out,
	update_slot
} from "../../../_snowpack/pkg/svelte/internal.js";

import Card from "./Card.svelte.js";
import Button from "./Button.svelte.js";

function create_default_slot(ctx) {
	let div;
	let button;
	let t;
	let current;
	button = new Button({ props: { prop: /*btnClose*/ ctx[1] } });
	const default_slot_template = /*#slots*/ ctx[2].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[3], null);

	return {
		c() {
			div = element("div");
			create_component(button.$$.fragment);
			t = space();
			if (default_slot) default_slot.c();
			attr(div, "data-testid", "close-modal");
			attr(div, "class", "btn--close svelte-1hoiz49");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(button, div, null);
			insert(target, t, anchor);

			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot(default_slot, default_slot_template, ctx, /*$$scope*/ ctx[3], dirty, null, null);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(button.$$.fragment, local);
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(button);
			if (detaching) detach(t);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let card;
	let div_class_value;
	let current;

	card = new Card({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div = element("div");
			create_component(card.$$.fragment);
			attr(div, "data-testid", "upper-div");
			attr(div, "class", div_class_value = "" + (null_to_empty(/*hideModal*/ ctx[0] ? "hideModal" : "modal") + " svelte-1hoiz49"));
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(card, div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const card_changes = {};

			if (dirty & /*$$scope*/ 8) {
				card_changes.$$scope = { dirty, ctx };
			}

			card.$set(card_changes);

			if (!current || dirty & /*hideModal*/ 1 && div_class_value !== (div_class_value = "" + (null_to_empty(/*hideModal*/ ctx[0] ? "hideModal" : "modal") + " svelte-1hoiz49"))) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(card.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(card.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(card);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { hideModal = true } = $$props;
	const toogleModal = () => $$invalidate(0, hideModal = !hideModal);

	const btnClose = {
		icon: "delete",
		style: "tertiary",
		onClick: toogleModal
	};

	$$self.$$set = $$props => {
		if ("hideModal" in $$props) $$invalidate(0, hideModal = $$props.hideModal);
		if ("$$scope" in $$props) $$invalidate(3, $$scope = $$props.$$scope);
	};

	return [hideModal, btnClose, slots, $$scope];
}

class Modal extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { hideModal: 0 });
	}
}

export default Modal;