import './Navbar.svelte.css';
/* src/lib/components/Navbar.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	noop,
	safe_not_equal,
	set_store_value,
	space,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import { user } from "../../stores/user.js";
import { signIn } from "../../firebase/firebase-auth.js";
import Avatar from "../atoms/Avatar.svelte.js";
import { landing } from "../../stores/landing.js";

function create_if_block(ctx) {
	let button;
	let img_1;
	let img_1_src_value;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			img_1 = element("img");
			attr(img_1, "class", "w-10 rounded-lg inline-block");
			if (img_1.src !== (img_1_src_value = "/icons/gofire.svg")) attr(img_1, "src", img_1_src_value);
			attr(img_1, "alt", "gofire logo");
			attr(button, "class", "svelte-3rdck3");
		},
		m(target, anchor) {
			insert(target, button, anchor);
			append(button, img_1);

			if (!mounted) {
				dispose = listen(img_1, "click", /*activeLanding*/ ctx[4]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let div0;
	let t;
	let div1;
	let avatar;
	let current;
	let if_block = /*$landing*/ ctx[3] == false && create_if_block(ctx);

	avatar = new Avatar({
			props: {
				onClick: signIn,
				img: /*img*/ ctx[2],
				toogleGuide: /*toogleGuide*/ ctx[0],
				guide: /*guide*/ ctx[1]
			}
		});

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			if (if_block) if_block.c();
			t = space();
			div1 = element("div");
			create_component(avatar.$$.fragment);
			attr(div0, "class", "float-left ");
			attr(div1, "class", "float-right");
			attr(div2, "class", "w-screen p-8");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			if (if_block) if_block.m(div0, null);
			append(div2, t);
			append(div2, div1);
			mount_component(avatar, div1, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*$landing*/ ctx[3] == false) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div0, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			const avatar_changes = {};
			if (dirty & /*img*/ 4) avatar_changes.img = /*img*/ ctx[2];
			if (dirty & /*toogleGuide*/ 1) avatar_changes.toogleGuide = /*toogleGuide*/ ctx[0];
			if (dirty & /*guide*/ 2) avatar_changes.guide = /*guide*/ ctx[1];
			avatar.$set(avatar_changes);
		},
		i(local) {
			if (current) return;
			transition_in(avatar.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(avatar.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			if (if_block) if_block.d();
			destroy_component(avatar);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let img;
	let $user;
	let $landing;
	component_subscribe($$self, user, $$value => $$invalidate(6, $user = $$value));
	component_subscribe($$self, landing, $$value => $$invalidate(3, $landing = $$value));

	let { toogleGuide = () => {
		console.log("click");
	} } = $$props;

	let { guide = false } = $$props;

	let { quitGuide = () => {
		
	} } = $$props;

	const activeLanding = () => {
		set_store_value(landing, $landing = true, $landing);
		quitGuide();
	};

	$$self.$$set = $$props => {
		if ("toogleGuide" in $$props) $$invalidate(0, toogleGuide = $$props.toogleGuide);
		if ("guide" in $$props) $$invalidate(1, guide = $$props.guide);
		if ("quitGuide" in $$props) $$invalidate(5, quitGuide = $$props.quitGuide);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$user*/ 64) {
			$: $$invalidate(2, img = $user.photoURL ? $user.photoURL : "icons/user.svg");
		}
	};

	return [toogleGuide, guide, img, $landing, activeLanding, quitGuide, $user];
}

class Navbar extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { toogleGuide: 0, guide: 1, quitGuide: 5 });
	}
}

export default Navbar;