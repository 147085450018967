/* src/lib/pages/Settings.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	add_flush_callback,
	append,
	bind,
	binding_callbacks,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "../../../_snowpack/pkg/svelte/internal.js";

import Button from "../atoms/Button.svelte.js";
import Card from "../atoms/Card.svelte.js";
import Input from "../atoms/Input.svelte.js";
import Tooltip from "../atoms/Tooltip.svelte.js";
import Grid from "../organisms/Grid.svelte.js";
import { data } from "../../stores/data.js";
import { goal } from "../../stores/goal.js";
import { t } from "../../i18n/i18n.js";

function create_default_slot_1(ctx) {
	let h1;
	let t0_value = /*$t*/ ctx[0]("SETTINGS.TITLE") + "";
	let t0;
	let t1;
	let h3;
	let t2_value = /*$t*/ ctx[0]("SETTINGS.SUBTITLE") + "";
	let t2;
	let t3;
	let input;
	let updating_value;
	let current;

	function input_value_binding(value) {
		/*input_value_binding*/ ctx[2](value);
	}

	let input_props = {
		label: /*$t*/ ctx[0]("SETTINGS.GOAL"),
		type: "text"
	};

	if (/*$goal*/ ctx[1] !== void 0) {
		input_props.value = /*$goal*/ ctx[1];
	}

	input = new Input({ props: input_props });
	binding_callbacks.push(() => bind(input, "value", input_value_binding));

	return {
		c() {
			h1 = element("h1");
			t0 = text(t0_value);
			t1 = space();
			h3 = element("h3");
			t2 = text(t2_value);
			t3 = space();
			create_component(input.$$.fragment);
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			append(h1, t0);
			insert(target, t1, anchor);
			insert(target, h3, anchor);
			append(h3, t2);
			insert(target, t3, anchor);
			mount_component(input, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if ((!current || dirty & /*$t*/ 1) && t0_value !== (t0_value = /*$t*/ ctx[0]("SETTINGS.TITLE") + "")) set_data(t0, t0_value);
			if ((!current || dirty & /*$t*/ 1) && t2_value !== (t2_value = /*$t*/ ctx[0]("SETTINGS.SUBTITLE") + "")) set_data(t2, t2_value);
			const input_changes = {};
			if (dirty & /*$t*/ 1) input_changes.label = /*$t*/ ctx[0]("SETTINGS.GOAL");

			if (!updating_value && dirty & /*$goal*/ 2) {
				updating_value = true;
				input_changes.value = /*$goal*/ ctx[1];
				add_flush_callback(() => updating_value = false);
			}

			input.$set(input_changes);
		},
		i(local) {
			if (current) return;
			transition_in(input.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(input.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(h3);
			if (detaching) detach(t3);
			destroy_component(input, detaching);
		}
	};
}

// (17:0) <Tooltip label="{$t('SETTINGS.TOOLTIPS.ADD')} ">
function create_default_slot(ctx) {
	let div;
	let button;
	let current;

	button = new Button({
			props: {
				prop: {
					icon: "add",
					style: "secondary",
					onClick: /*func*/ ctx[3]
				}
			}
		});

	return {
		c() {
			div = element("div");
			create_component(button.$$.fragment);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(button, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const button_changes = {};

			if (dirty & /*$t*/ 1) button_changes.prop = {
				icon: "add",
				style: "secondary",
				onClick: /*func*/ ctx[3]
			};

			button.$set(button_changes);
		},
		i(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(button);
		}
	};
}

function create_fragment(ctx) {
	let card;
	let t0;
	let tooltip;
	let t1;
	let grid;
	let current;

	card = new Card({
			props: {
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	tooltip = new Tooltip({
			props: {
				label: "" + (/*$t*/ ctx[0]("SETTINGS.TOOLTIPS.ADD") + " "),
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	grid = new Grid({ props: { edit: true } });

	return {
		c() {
			create_component(card.$$.fragment);
			t0 = space();
			create_component(tooltip.$$.fragment);
			t1 = space();
			create_component(grid.$$.fragment);
		},
		m(target, anchor) {
			mount_component(card, target, anchor);
			insert(target, t0, anchor);
			mount_component(tooltip, target, anchor);
			insert(target, t1, anchor);
			mount_component(grid, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const card_changes = {};

			if (dirty & /*$$scope, $t, $goal*/ 19) {
				card_changes.$$scope = { dirty, ctx };
			}

			card.$set(card_changes);
			const tooltip_changes = {};
			if (dirty & /*$t*/ 1) tooltip_changes.label = "" + (/*$t*/ ctx[0]("SETTINGS.TOOLTIPS.ADD") + " ");

			if (dirty & /*$$scope, $t*/ 17) {
				tooltip_changes.$$scope = { dirty, ctx };
			}

			tooltip.$set(tooltip_changes);
		},
		i(local) {
			if (current) return;
			transition_in(card.$$.fragment, local);
			transition_in(tooltip.$$.fragment, local);
			transition_in(grid.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(card.$$.fragment, local);
			transition_out(tooltip.$$.fragment, local);
			transition_out(grid.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(card, detaching);
			if (detaching) detach(t0);
			destroy_component(tooltip, detaching);
			if (detaching) detach(t1);
			destroy_component(grid, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $t;
	let $goal;
	component_subscribe($$self, t, $$value => $$invalidate(0, $t = $$value));
	component_subscribe($$self, goal, $$value => $$invalidate(1, $goal = $$value));

	function input_value_binding(value) {
		$goal = value;
		goal.set($goal);
	}

	const func = () => data.add($t("SETTINGS.HEADER"));
	return [$t, $goal, input_value_binding, func];
}

class Settings extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Settings;